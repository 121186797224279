import React, {useContext, useMemo, useState} from 'react';
import { useTable, useSortBy, usePagination, useRowSelect } from 'react-table';
import AuthContext from "../../contexts/AuthContext";
import axios from "axios";
import Loader from "react-loader-spinner";
import moment from 'moment';
import {Link, useRouteMatch} from "react-router-dom";
import {toast} from "react-toastify";
import emptyIcon from '../../assets/images/empty-list.png';
import toastOptions from '../../assets/constants/toast';

const CustomList = ({ entity, columns, setSelectedColumns, setSelectedIds, refreshData, intermediatePath, strictPath, activeFilters, setData, isSelectable = true, isPaginable = true }) => {

    const { token } = useContext(AuthContext);
    const [items, setItems] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const data = useMemo(() => items, [items]);
    let { path } = useRouteMatch();

    React.useEffect(() => {
        setIsLoaded(false);
        axios.get(process.env.REACT_APP_UPA_API_HOST + entity, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(typeof setData === 'function') {
                    setData(response.data);
                }
                applyFilters(response.data);
                console.log(response.data);
                setIsLoaded(true);
            })
            .catch((error) => {
                console.log(error)
                toast.error(error.message, toastOptions);
                setIsLoaded(true);
            });
    }, [entity, refreshData, token, setData, activeFilters]);

    const applyFilters = (data) => {
        let filteredData = [...data];
        activeFilters && activeFilters.forEach(column => {
            let columnData = [];
            if(column.icon !== 'calendar') {
                column.selectedValues && column.selectedValues.forEach(value => {
                    switch(true) {
                        case column.accessor === 'project.name' || column.accessor === 'customer.name' || column.accessor === 'provider' || column.accessor === 'sender':
                            columnData = columnData.concat(data.filter(row => row[column.accessor.split('.')[0]].name === value.value))
                            break;
                        case column.accessor === 'project_managers.0':
                            columnData = columnData.concat(data.filter(row => row.project_managers[0].firstName + ' ' + row.project_managers[0].lastName === value.value))
                            break;
                        default:
                            columnData = columnData.concat(data.filter(row => row[column.accessor] === value.value))
                            break;
                    }
                })
            } else {
                columnData = data.filter(row => {
                    if(row[column.accessor]) {
                        if(column.startDate && column.stopDate) {
                            return moment(row[column.accessor]).isBetween(column.startDate, column.stopDate);
                        } else if (column.startDate && !column.stopDate) {
                            return moment(row[column.accessor]).isSameOrAfter(column.startDate);
                        } else if (!column.startDate && column.stopDate) {
                            return moment(row[column.accessor]).isSameOrBefore(column.stopDate);
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                });
            }
            if((column.icon === 'calendar' && (column.startDate || column.stopDate)) || columnData.length > 0) {
                filteredData = filteredData.filter(row => columnData.includes(row))
            }
        })
        setItems(filteredData);
    }

    const { getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        selectedFlatRows,
        state: { pageIndex, pageSize, selectedRowIds }} = useTable({ columns, data },
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
            isSelectable && hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    Header: ({ getToggleAllPageRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ])
        });

    React.useEffect(() => {
        if(typeof setSelectedColumns === 'function' && typeof setSelectedIds === 'function') {
            setSelectedColumns(Object.keys(selectedRowIds).length);
            setSelectedIds(selectedFlatRows.map((row) => { return row.original.id }));
            setPageSize(20);
        }
    }, [selectedRowIds, setSelectedColumns, setPageSize, selectedFlatRows, setSelectedIds]);

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }, ref) => {
            const defaultRef = React.useRef()
            const resolvedRef = ref || defaultRef

            React.useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate
            }, [resolvedRef, indeterminate])

            return (
                <label className="checkbox-container">
                    <input type="checkbox" ref={resolvedRef} {...rest} />
                    <span className="checkmark"/>
                </label>
            )
        }
    )

    return isLoaded ? (
        <div className='list-container'>
            <table {...getTableProps()}>
                <thead>
                { headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        { headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className={column.icon ? 'column-icon ' + column.icon : '' + (column.id === 'selection' ? 'checkbox-fixed-size' : '')}>
                                {column.render('Header')}
                                {column.isSorted ? column.isSortedDesc ? <span className='sorted' /> : <span className='sorted-desc' /> : ''}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                { items.length > 0 ? (
                    <tbody {...getTableBodyProps()}>
                    { page.map((row) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>
                                        { cell.column.id === 'selection' ? cell.render('Cell') : <Link to={ strictPath ? '../' + strictPath + '/' + cell.row.original.id : (path + (intermediatePath ? intermediatePath : '') + '/' + cell.row.original.id)} >{cell.render('Cell')}</Link> }
                                    </td>
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                ) : (
                    <tbody>
                    <tr>
                        <td colSpan={7}>
                            <div className='empty-list-container'>
                                <img src={emptyIcon} alt='icon' width={120} />
                                <span>Il n'y a encore aucun élément dans cette liste !</span>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                )}

            </table>
            { isPaginable && items.length > 0 ? (
                <div className='pagination-container'>
                    <div className="current-page-info">
                        <span>Page <strong>{pageIndex + 1} sur {pageOptions.length}</strong></span>
                    </div>
                    <div className='page-switcher'>
                        <button className='pagination-button' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                        <button className='pagination-button' onClick={previousPage} disabled={!canPreviousPage}>{'<'}</button>
                        <button className='pagination-button' onClick={nextPage} disabled={!canNextPage}>{'>'}</button>
                        <button className='pagination-button' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                    </div>
                    <div className='rows-by-page'>
                        <span className='padding-right'>Lignes par page</span>
                        <select value={pageSize}
                                className='pagination-button select'
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}> {[20, 50, 100, 200].map(pageSize => (
                            <option key={pageSize} value={pageSize}>{pageSize}</option>
                        ))}
                        </select>
                    </div>
                </div>
            ) : null }
        </div>
    ) : <div className='loader-container'><Loader type="Oval" color="#2C95E8" height={30} width={30} /></div>

}

export default CustomList;