import React, {useContext, useState} from "react";
import {useHistory} from "react-router-dom";
import CustomSearchInput from "../../custom/CustomSearchInput";
import {useForm} from "react-hook-form";
import axios from "axios";
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import {toast} from "react-toastify";
import AuthContext from "../../../contexts/AuthContext";
import Loader from "react-loader-spinner";
import CustomTag from "../../custom/CustomTag";
import moment from "moment";
import toastOptions from "../../../assets/constants/toast";

const OrderEdit = ({order, setOrder, refreshData, setRefreshData}) => {

    const [buttonLoading, setButtonLoading] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const history = useHistory();
    const { token } = useContext(AuthContext);

    const handleUpdate = () => {
        setButtonLoading(true);
        order.files.forEach((file) => {
            if(file.image) {
                file.fileName = file.name;
                file.fileType = file.type;
            }
        });
        axios.put(process.env.REACT_APP_UPA_API_HOST + 'orders/' + order.id , {order}, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                console.log(response);
                setRefreshData(refreshData + 1);
                setButtonLoading(false);
                toast.success("Le lancement a bien été modifié", toastOptions);
                history.goBack();
            })
            .catch((error) => {
                toast.error(error.response.data.message, toastOptions);
                setButtonLoading(false);
            });
    }

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if(file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                console.log(reader);
                setOrder((order) => {
                    file.image = reader.result;
                    let tab = order.files;
                    tab = [...tab, file];
                    return {...order, files: tab }
                })
            }
        }
    }

    const handleDeleteFile = (index) => {
        setOrder((order) => {
            const tab = [...order.files];
            tab.splice(index, 1);
            return {...order, files: tab}
        })
    }

    React.useEffect(() => {
        console.log('edit order : ', order);
    });

    return (
        <div>
            <div className='list-header align-items-start'>
                <div className='header-input view-title'>
                    <input {...register("orderName", { required: true })}
                           type='text'
                           className={(errors.orderName ? 'name-input-required' : '')}
                           placeholder='Nom du lancement'
                           value={order.name}
                           onChange={(e) => {setOrder((order) => {return {...order, name: e.target.value}})}} />
                    <span className='view-id'>#{order.id}</span>
                </div>
                <div className='header-buttons'>
                    <button className='main-button without-icon warning-button' onClick={() => {
                        setRefreshData(refreshData + 1);
                        history.goBack();
                    }} >Annuler</button>
                    <button className='main-button blue-button validate margin-left' onClick={handleSubmit(handleUpdate)} disabled={buttonLoading}>{ buttonLoading ? <Loader type="Oval" color="#FFFFFF" height={30} width={20} /> : 'Sauvegarder' }</button>
                </div>
            </div>
            <div className='input-group without-top'>
                <div className='input-row-container'>
                    <span className="input-label role">Statut</span>
                    <CustomTag value={order.status} />
                </div>
                <div className='input-row-container'>
                    <span className="input-label calendar">Date d'expédition</span>
                    <div className='input-date-container'>
                        <span className='date-view'>{ order.deliveryDate ? moment(order.deliveryDate).format('DD/MM/YYYY') : 'Non renseignée' }</span>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label folder">Projet</span>
                    <CustomTag value={order.project.name} />
                </div>
                <div className='input-row-container'>
                    <span className="input-label provider">Fournisseur</span>
                    <CustomTag value={order.recipient.name} />
                </div>
                <div className='input-row-container'>
                    <span className="input-label location">Livraison</span>
                    <div className='input-date-container'>
                        <span className='date-view'>{order.recipient.address}</span>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className='input-label user'>Contacts <span className='required'>*</span></span>
                    <div className='input-container'>
                        <CustomSearchInput placeholder='Rechercher un contact'
                                           entity={order.senderId > 0 ? 'providers/' + order.senderId + '/contacts' : []}
                                           labelType='user'
                                           value={order.contacts}
                                           isMultiSearch={true}
                                           onChange={(newValue) => { setOrder((order) => { return { ...order, contacts:newValue }}); }}
                        />
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className='input-label file'>Fichiers</span>
                    <label htmlFor='file-add' className='main-button small-button blue-button'>Ajouter un fichier</label>
                    <input type='file' id='file-add' name='file-add' placeholder='Ajouter un fichier' onChange={(e) => handleFileUpload(e)}/>
                </div>
                <div className='files-container'>
                    { order.files && order.files.map((file, index) => (
                        <div className='d-inline-block' key={index}>
                            { file.image ? (
                                <div className='file-block'>
                                    <div className='file-preview'>
                                        { file.type === "application/pdf" ? (
                                            <Document file={file.image}>
                                                <Page pageNumber={1} className='custom-page-file'/>
                                            </Document>
                                        ) : (
                                            <img src={file.image} alt='desc' />
                                        )}
                                    </div>
                                    <div className='file-info'>
                                        <span>{file.name}</span>
                                        <div className='file-buttons'>
                                            <button className='file-button delete' onClick={() => handleDeleteFile(index)} />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='file-block'>
                                    <div className='file-preview'>
                                        { file.fileType === "application/pdf" ? (
                                            <Document file={process.env.REACT_APP_UPA_API_HOST + 'files/' + file.id + file.extension}>
                                                <Page pageNumber={1} className='custom-page-file'/>
                                            </Document>
                                        ) : (
                                            <img src={process.env.REACT_APP_UPA_API_HOST + 'files/' + file.id + file.extension} alt='desc' />
                                        )}
                                    </div>
                                    <div className='file-info'>
                                        <span>{file.fileName}</span>
                                        <div className='file-buttons'>
                                            <a href={process.env.REACT_APP_UPA_API_HOST + 'files/' + file.id + file.extension} target='_blank' rel="noreferrer"><button className='file-button view' /></a>
                                            <button className='file-button delete' onClick={() => handleDeleteFile(index)} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <div className='input-row-container'>
                    <span className='input-label reminder'>Relances</span>
                    <div className='frequency-container'>
                        <span>Tous les</span>
                        <input type='number' min={0} max={99} value={order.frequency} onChange={(e) => {setOrder((order) => {return {...order, frequency: e.target.value ? parseInt(e.target.value) : 0 }})}} />
                        <select value={order.timeFrequency} onChange={(e) => {setOrder((order) => {return {...order, timeFrequency: e.target.value}})}}>
                            <option value='days'>jours</option>
                            <option value='weeks'>semaines</option>
                            <option value='months'>mois</option>
                        </select>
                    </div>
                </div>
                <div className='input-textarea-container'>
                    <span className='input-label pen'>Notes internes</span>
                    <div className='input-textarea'>
                        <textarea placeholder='Ajoutez une note interne...' rows={6} value={order.internalNotes} onChange={(e) => {setOrder((order) => {return {...order, internalNotes: e.target.value}})}}/>
                    </div>
                    <span className='required'>* Champs obligatoires</span>
                </div>
            </div>
        </div>
    );
}

export default OrderEdit;
