import React, {useContext, useState} from "react";
import {Link, Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import CustomList from "../../custom/CustomList";
import CustomTag from "../../custom/CustomTag";
import User from "./User";
import UserEdit from "./UserEdit";
import UserAdd from "./UserAdd";
import {toast} from "react-toastify";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import toastOptions from "../../../assets/constants/toast";

const Settings = () => {

    let { path } = useRouteMatch();
    const { token } = useContext(AuthContext);
    const [selectedColumns, setSelectedColumns] = useState(0);
    const [selectedIds, setSelectedIds] = useState([]);
    const [refreshCounter, setRefreshCounter] = useState(0);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Prénom',
                accessor: 'firstName',
                icon: 'user'
            },
            {
                Header: 'Nom',
                accessor: 'lastName',
                icon: 'user'
            },
            {
                Header: 'Email',
                accessor: 'email',
                icon: 'email'
            },
            {
                Header: 'Rôle',
                accessor: 'role',
                icon: 'role',
                Cell: ({ cell: { value } }) => (<CustomTag value={value} />)
            },
            {
                Header: 'Statut',
                accessor: 'status',
                icon: 'role',
                Cell: ({ cell: { value } }) => (<CustomTag value={value} />)
            }
        ],
        []
    )

    const handleBulkArchive = () => {
        axios.put(process.env.REACT_APP_UPA_API_HOST + 'users/bulk-archive', { selectedIds }, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                toast.success('Utilisateurs archivés', toastOptions);
                setRefreshCounter(refreshCounter + 1);
            })
            .catch(err => {
                toast.error(err.response.data.message, toastOptions);
            });
    }

    const handleBulkDelete = () => {
        if (window.confirm("Attention : vous êtes sur le point de supprimer plusieurs utilisateurs")) {
            axios.post(process.env.REACT_APP_UPA_API_HOST + 'users/bulk-delete', { selectedIds }, { headers: { Authorization: 'Bearer ' + token }})
                .then(() => {
                    toast.success('Utilisateurs supprimés', toastOptions);
                    setRefreshCounter(refreshCounter + 1);
                })
                .catch(err => {
                    toast.error(err.response.data.message, toastOptions);
                });
        }
    }

    return (
        <Switch>
            <Route exact path={path}>
                <div className='list-header'>
                    <div className='header-title settings'>
                        <h1>Paramètres</h1>
                    </div>
                </div>
                <div className='list-header without-top'>
                    <div className='header-title users'>
                        <h2>Utilisateurs</h2>
                    </div>
                    <div className='header-buttons'>
                        { selectedColumns === 0 ?
                            <Link to={path + '/users/new'}>
                                <button className='main-button blue-button plus'>Créer un utilisateur</button>
                            </Link> :
                            <div>
                                <button className='main-button archive' onClick={handleBulkArchive}>Archiver</button>
                                <button className='main-button warning-button delete' onClick={handleBulkDelete}>Supprimer</button>
                            </div>
                        }
                    </div>
                </div>
                <CustomList entity='users'
                            refreshData={refreshCounter}
                            columns={columns}
                            setSelectedColumns={setSelectedColumns}
                            setSelectedIds={setSelectedIds}
                            intermediatePath='/users'
                />
            </Route>
            <Route path={path + '/users/new'}>
                <UserAdd />
            </Route>
            <Route path={path + '/users/:userId/edit'}>
                <UserEdit />
            </Route>
            <Route path={path + '/users/:userId'}>
                <User />
            </Route>
            <Route path={path + '*'}>
                <Redirect to={path} />
            </Route>
        </Switch>
    );
}

export default Settings;
