import React from "react";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import OrderList from "./OrderList";
import OrderAdd from "./OrderAdd";
import OrderContainer from "./OrderContainer";

const Orders = () => {

    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <OrderList />
            </Route>
            <Route path={path + '/new'}>
                <OrderAdd />
            </Route>
            <Route path={path + "/:orderId"}>
                <OrderContainer />
            </Route>
            <Route path={ path + '*'}>
                <Redirect to={path} />
            </Route>
        </Switch>
    );
}

export default Orders;
