import {Switch, Route, Redirect} from "react-router-dom";
import Login from "./components/login/Login";
import AuthContext from "./contexts/AuthContext";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import React, { useState } from "react";
import './assets/styles/App.css';
import axios from "axios";
import AdminPanel from "./components/admin/AdminPanel";
import PasswordForgot from "./components/login/PasswordForgot";
import PrivateRoute from "./components/routes/PrivateRoute";
import OrderPortal from "./components/orders/OrderPortal";
import Loader from "react-loader-spinner";

const App = () => {

    const [token, setToken] = useState(localStorage.getItem('session_token'));
    const [userLoaded, setUserLoaded] = useState(false);
    const [user, setUser] = useState(null);

    const contextValue = {
        token: token,
        user:user,
        setToken: setToken,
        setUser:setUser
    }

    React.useEffect( () => {
        setUserLoaded(false);
        if(localStorage.getItem('session_token')) {
            axios.get(process.env.REACT_APP_UPA_API_HOST + 'users/me' , { headers:{ "Authorization": "Bearer " + localStorage.getItem('session_token') }})
                .then((response) => {
                    setUser(response.data);
                    setUserLoaded(true);
                })
                .catch((error) => {
                    console.log(error);
                    setUserLoaded(true);
                });
        } else {
            setUserLoaded(true);
        }
    }, [token]);

    return userLoaded ? (
        <AuthContext.Provider value={contextValue}>
            <Switch>
                <PrivateRoute path="/admin">
                    <AdminPanel />
                </PrivateRoute>
                <Route exact path="/orders/:orderToken">
                    <OrderPortal />
                </Route>
                <Route exact path="/login">
                    <Login />
                </Route>
                <Route exact path="/forgot-password">
                    <PasswordForgot />
                </Route>
                <Route path="/">
                    <Redirect to="/admin" />
                </Route>
            </Switch>
        </AuthContext.Provider>
    ) : <div className='loader-container'><Loader type="Oval" color="#2C95E8" height={30} width={30} /></div>;
}

export default App;
