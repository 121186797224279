import React, {useContext, useState} from 'react';
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import CustomOption from "../../custom/CustomOption";
import Select from "react-select";
import constants from "../../../assets/constants/constants";
import { useForm } from "react-hook-form";
import Switch from "react-switch";
import toastOptions from "../../../assets/constants/toast";

const UserEdit = () => {

    const { userId } = useParams();
    const [user, setUser] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const { token } = useContext(AuthContext);
    const history = useHistory();
    const { register, handleSubmit, formState: { errors } } = useForm();

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'users/' + userId, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    console.log(response.data);
                    if(response.data.status === "archived") {
                        history.goBack();
                    } else {
                        setUser(response.data);
                        setIsLoaded(true);
                    }
                } else {
                    toast.error('Utilisateur inconnu', toastOptions);
                    history.push('/admin/settings');
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [history, token, userId]);

    const handleUpdate = () => {
        setButtonLoading(true);
        axios.put(process.env.REACT_APP_UPA_API_HOST + 'users/' + userId, { user }, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                console.log(response);
                setButtonLoading(false);
                toast.success("L'utilisateur a bien été modifié", toastOptions);
                history.goBack();
            })
            .catch((error) => {
                toast.error(error.response.data.message, toastOptions);
                setButtonLoading(false);
            });
    }

    const handleCheck = () => {
        if(user.status === "active") {
            setUser((user) => { return { ...user, status:"inactive" }});
        } else {
            setUser((user) => { return { ...user, status:"active" }});
        }
    }

    return isLoaded ? (
        <div className='view-container'>
            <div className='view-header'>
                <div className='view-title'>
                    <h1>{user.firstName + ' ' + user.lastName}</h1>
                    <span className='view-id'>#{user.id}</span>
                </div>
                <div className='view-buttons'>
                    <button onClick={history.goBack} className='main-button without-icon warning-button'>Annuler</button>
                    <button className='main-button blue-button validate margin-left' onClick={handleSubmit(handleUpdate)} disabled={buttonLoading}>{ buttonLoading ? <Loader type="Oval" color="#FFFFFF" height={30} width={20} /> : 'Sauvegarder' }</button>
                </div>
            </div>
            <div className='input-row-container'>
                <span className="input-label user">Actif</span>
                <div className='input-switcher'>
                    <Switch onChange={handleCheck}
                            checked={user.status === "active"}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={15}
                            width={35}/>
                </div>
            </div>
            <div className='input-row-container'>
                <span className="input-label user">Prénom <span className='required'>*</span></span>
                <div className='input-container without-icon'>
                    <input value={user.firstName} className={'classic-input ' + (errors.firstName ? 'input-required' : '')} placeholder='Entrez un prénom' {...register("firstName", { required: true })} onChange={(e) => {
                        setUser((user) => { return { ...user, firstName:e.target.value }})
                    }}/>
                </div>
            </div>
            <div className='input-row-container'>
                <span className="input-label user">Nom <span className='required'>*</span></span>
                <div className='input-container without-icon'>
                    <input value={user.lastName} className={'classic-input ' + (errors.lastName ? 'input-required' : '')} placeholder='Entrez un nom' {...register("lastName", { required: true })} onChange={(e) => {
                        setUser((user) => { return { ...user, lastName:e.target.value }})
                    }}/>
                </div>
            </div>
            <div className='input-row-container'>
                <span className="input-label email">Email <span className='required'>*</span></span>
                <div className='input-container without-icon'>
                    <input value={user.email} className={'classic-input ' + (errors.email ? 'input-required' : '')} placeholder='Entrez une adresse email' {...register("email", { required: true })} onChange={(e) => {
                        setUser((user) => { return { ...user, email:e.target.value }})
                    }}/>
                </div>
            </div>
            <div className='input-row-container'>
                <span className='input-label role'>Rôle <span className='required'>*</span></span>
                <div className='input-container'>
                    <Select
                        isMulti={false}
                        name="colors"
                        options={constants.roles}
                        className={"basic-single single-search-input"}
                        classNamePrefix="select"
                        defaultValue={constants.roles.find((e) => { return e.value === user.role })}
                        placeholder="Rechercher un rôle"
                        onChange={(newValue) => { setUser((user) => { return { ...user, role:newValue.value }})}}
                        components={{ Option: CustomOption }}
                    />
                </div>
            </div>
        </div>
    ) : (<div className='loader-container'><Loader type="Oval" color="#2C95E8" height={30} width={30} /></div>)
}

export default UserEdit;