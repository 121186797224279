import React, {useContext, useState} from 'react';
import {Link, useHistory, useParams, useRouteMatch} from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import CustomTag from "../../custom/CustomTag";
import Tippy from "@tippyjs/react";
import moment from "moment";
import CustomList from "../../custom/CustomList";
import toastOptions from '../../../assets/constants/toast'

const ProjectView = () => {

    const { projectId } = useParams();
    const [project, setProject] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const { token } = useContext(AuthContext);
    const history = useHistory();
    let { url } = useRouteMatch();
    const [popoverVisible, setPopoverVisible] = useState(false);
    const show = () => setPopoverVisible(true);
    const hide = () => setPopoverVisible(false);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Nom',
                accessor: 'name',
                isFiltered: true,
                icon: 'pen',
            },
            {
                Header: 'Fournisseur',
                accessor: 'sender',
                isFiltered: true,
                icon: 'provider',
                Cell: ({ cell: { value } }) => (<CustomTag value={value.type} label={value.name} />)
            },
            {
                Header: 'Statut',
                accessor: 'status',
                isFiltered: true,
                icon: 'role',
                Cell: ({ cell: { value } }) => (<CustomTag value={value} />)
            },
            {
                Header: "Date d'expédition",
                accessor: 'deliveryDate',
                isFiltered: true,
                icon: 'calendar',
                Cell: ({ cell: { value } }) => value ? (<span>{ moment(value).format('DD MMMM YYYY') }</span>) : (<span className='erased'>Non renseignée</span>)
            }
        ],
        []
    )

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'projects/' + projectId, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    console.log(response.data);
                    setProject(response.data);
                    setIsLoaded(true);
                } else {
                    toast.error('Projet inconnu', toastOptions);
                    history.push('/projects');
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [history, token, projectId]);

    const handleArchive = () => {
        if(project && project.status === "archived") {
            axios.put(process.env.REACT_APP_UPA_API_HOST + 'projects/' + projectId, { project: {...project, status:"active" }}, { headers: { Authorization: 'Bearer ' + token }})
                .then(() => {
                    toast.success('Projet rétabli', toastOptions);
                    setProject({...project, status:"active" });
                })
                .catch(err => {
                    toast.error(err.response.data.message, toastOptions);
                })
        } else {
            axios.put(process.env.REACT_APP_UPA_API_HOST + 'projects/' + projectId, { project: {...project, status:"archived" }}, { headers: { Authorization: 'Bearer ' + token }})
                .then(() => {
                    toast.success('Projet archivé', toastOptions);
                    setProject({...project, status:"active" });
                })
                .catch(err => {
                    toast.error(err.response.data.message, toastOptions);
                })
        }
    }

    const handleDelete = () => {
        if (window.confirm("Attention : toutes les données de ce projet seront perdues.")) {
            axios.delete(process.env.REACT_APP_UPA_API_HOST + 'projects/' + projectId, { headers: { Authorization: 'Bearer ' + token }})
                .then(() => {
                    toast.success('Projet supprimé', toastOptions);
                    history.goBack();
                })
                .catch(err => {
                    toast.error(err.response.data.message, toastOptions);
                });
        }
    }

    const popover = (
        <div className='user-options' onClick={popoverVisible ? hide : show}>
            <Link to={'/admin/orders/new?projectId=' + projectId}>
                <div className='user-options-item launch'>
                    <span>Ajouter un lancement</span>
                </div>
            </Link>
            <div className='user-options-item archive' onClick={handleArchive}>
                <span>{project && project.status === "archived" ? 'Rétablir' : 'Archiver'}</span>
            </div>
            <div className='user-options-item delete' onClick={handleDelete}>
                <span>Supprimer</span>
            </div>
        </div>
    );

    return isLoaded ? (
        <div className='view-container'>
            <div className='view-header'>
                <div className='view-title'>
                    <h1>{project.name}</h1>
                    <span className='view-id'>#{project.id}</span>
                </div>
                <div className='view-buttons'>
                    <button className='main-button return-button margin-right' onClick={() => history.goBack()} />
                    { project.status === "archived" ? null :
                        <Link to={ url + '/edit' }>
                            <button className='main-button blue-button edit'>Modifier</button>
                        </Link>
                    }
                    <Tippy content={popover}
                           allowHTML={true}
                           placement={'bottom-end'}
                           offset={[0,5]}
                           visible={popoverVisible}
                           onClickOutside={hide}
                           interactive={true}
                           appendTo={'parent'}>
                        <button className='squared-button margin-left' onClick={popoverVisible ? hide : show} />
                    </Tippy>
                </div>
            </div>
            <div className='input-row-container'>
                <span className="input-label role">Statut</span>
                <CustomTag value={project.status} />
            </div>
            <div className='input-row-container'>
                <span className="input-label customer">Client</span>
                <CustomTag value={project.customer.name} />
            </div>
            <div className='input-row-container'>
                <span className="input-label user">Responsable(s)</span>
                { project.project_managers.map((user, index) => (
                    <CustomTag value={user.firstName + ' ' + user.lastName} className='margin-right' key={index} />
                ))}
            </div>
            <div className='input-row-container'>
                <span className="input-label calendar">Date limite</span>
                <div className='input-date-container'>
                    <span className='date-view'>{ moment(project.deliveryEstimation).format('DD/MM/YYYY') }</span>
                </div>
            </div>
            { project.orders.length > 0 ? (
                <div className='input-orders-container'>
                    <span className="input-label order">Lancements</span>
                    <CustomList entity={'projects/' + projectId + '/orders'} columns={columns} isSelectable={false} isPaginable={false} strictPath='orders' />
                </div>
            ) : (
                <div className='input-row-container'>
                    <span className="input-label order">Lancements</span>
                    <CustomTag value="Ce projet ne possède aucun lancement" />
                </div>
            )}
            <div className='input-textarea-container'>
                <span className='input-label pen'>Notes internes</span>
                <div className='textarea-view'>{project.internalNotes}</div>
            </div>
        </div>
    ) : (<div className='loader-container'><Loader type="Oval" color="#2C95E8" height={30} width={30} /></div>)
}

export default ProjectView;