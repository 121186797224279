import React, {useContext, useState} from "react";
import {Link, useRouteMatch} from "react-router-dom";
import CustomList from "../../custom/CustomList";
import moment from "moment";
import {toast} from "react-toastify";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import OrderStatusBar from "./OrderStatusBar";
import CustomFilter from "../../custom/CustomFilter";
import CustomDownloader from "../../custom/CustomDownloader";
import toastOptions from "../../../assets/constants/toast";

const ProjectList = () => {

    let { path } = useRouteMatch();
    const [selectedColumns, setSelectedColumns] = useState(0);
    const [selectedIds, setSelectedIds] = useState([]);
    const [refreshData, setRefreshData] = useState(0);
    const [data, setData] = useState(null);
    const [filterOpen, setFilterOpen] = useState(false);
    const [activeFilters, setActiveFilters] = useState([]);
    const { token } = useContext(AuthContext);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Nom',
                accessor: 'name',
                isFiltered: true,
                icon: 'pen'
            },
            {
                Header: 'Id',
                accessor: 'id',
                isFiltered: true,
                icon: 'id'
            },
            {
                Header: 'Client',
                accessor: 'customer.name',
                isFiltered: true,
                icon: 'customer'
            },
            {
                Header: 'Responsable',
                accessor: 'project_managers.0',
                icon: 'user',
                isDownloaded: false,
                isFiltered: true,
                Cell: ({ cell: { value } }) => (<span>{value.firstName + " " + value.lastName}</span>)
            },
            {
                Header: 'Lancements',
                accessor: 'id',
                id: 'orders',
                icon: 'order',
                isFiltered: false,
                isDownloaded: false,
                Cell: ({ cell: { value } }) => (<OrderStatusBar projectId={value} />)
            },
            {
                Header: 'Date limite',
                accessor: 'deliveryEstimation',
                icon: 'calendar',
                isFiltered: true,
                Cell: ({ cell: { value } }) => (<span>{moment(value).format('DD/MM/YYYY')}</span>)
            }
        ],
        []
    );

    const handleBulkArchive = () => {
        axios.put(process.env.REACT_APP_UPA_API_HOST + 'providers/bulk-archive', { selectedIds }, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                toast.success('Projets archivés', toastOptions);
                setRefreshData(refreshData + 1)
            })
            .catch(err => {
                toast.error(err.response.data.message, toastOptions);
            })
    }

    const handleBulkDelete = () => {
        if (window.confirm("Attention : vous êtes sur le point de supprimer plusieurs projets")) {
            axios.post(process.env.REACT_APP_UPA_API_HOST + 'projects/bulk-delete', { selectedIds }, { headers: { Authorization: 'Bearer ' + token }})
                .then(() => {
                    toast.success('Projets supprimés', toastOptions);
                    setRefreshData(refreshData + 1);
                })
                .catch(err => {
                    toast.error(err.response.data.message, toastOptions);
                });
        }
    }

    return (
        <div className='page-content'>
            <div className='list-header'>
                <div className='header-title'>
                    <h1>Les projets</h1>
                </div>
                <div className='header-buttons'>
                    { selectedColumns === 0 ?
                        <div>
                            <Link to={path + '/new'}>
                                <button className='main-button blue-button plus'>Créer un projet</button>
                            </Link>
                            <button className='main-button filter' onClick={() => setFilterOpen(true)} >Filtrer</button>
                            <CustomDownloader data={data} columns={columns} filename={'projets.csv'} />
                        </div>
                        :
                        <div>
                            <button className='main-button archive' onClick={handleBulkArchive}>Archiver</button>
                            <button className='main-button warning-button delete' onClick={handleBulkDelete}>Supprimer</button>
                        </div>
                    }
                </div>
                <CustomFilter columns={columns} data={data} setData={setData} setOpen={setFilterOpen} open={filterOpen} setActiveFilters={setActiveFilters} />
            </div>
            <CustomList entity='projects' columns={columns} setSelectedColumns={setSelectedColumns} setSelectedIds={setSelectedIds} setData={setData} activeFilters={activeFilters} refreshData={refreshData} />
        </div>
    );
}

export default ProjectList;
