import React from "react";
import moment from "moment";

const CustomAction = ({action}) => {

    return (
        <div className='custom-action'>
            <div className='action-date'>
                <span>{moment(action.createdAt).format('DD MMMM YYYY à HH:mm')}</span>
            </div>
            <div className={'action-block ' + action.type}>
                <span>{action.content}</span>
            </div>
        </div>
    );
}

export default CustomAction;