import {useContext, useState} from 'react';
import {Link, useHistory, useParams, useRouteMatch} from "react-router-dom";
import Tippy from "@tippyjs/react";
import CustomTag from "../../custom/CustomTag";
import moment from "moment";
import {Document, Page} from "react-pdf/dist/umd/entry.webpack";
import constants from "../../../assets/constants/constants";
import React from "react";
import {toast} from "react-toastify";
import axios from "axios";
import { saveAs } from "file-saver";
import AuthContext from "../../../contexts/AuthContext";
import CustomList from "../../custom/CustomList";
import toastOptions from "../../../assets/constants/toast";

const OrderView = ({order, setOrder}) => {

    const { token } = useContext(AuthContext);
    const { orderId } = useParams();
    const history = useHistory();
    let { url } = useRouteMatch();
    const [popoverVisible, setPopoverVisible] = useState(false);
    const show = () => setPopoverVisible(true);
    const hide = () => setPopoverVisible(false);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Nom',
                accessor: 'name',
                isFiltered: true,
                icon: 'pen',
            },
            {
                Header: 'Fournisseur',
                accessor: 'sender',
                isFiltered: true,
                icon: 'provider',
                Cell: ({ cell: { value } }) => (<CustomTag value={value.type} label={value.name} />)
            },
            {
                Header: 'Statut',
                accessor: 'status',
                isFiltered: true,
                icon: 'role',
                Cell: ({ cell: { value } }) => (<CustomTag value={value} />)
            },
            {
                Header: "Date d'expédition",
                accessor: 'deliveryDate',
                isFiltered: true,
                icon: 'calendar',
                Cell: ({ cell: { value } }) => value ? (<span>{ moment(value).format('DD MMMM YYYY') }</span>) : (<span className='erased'>Non renseignée</span>)
            }
        ],
        []
    )

    const handleDelete = () => {
        if (window.confirm("Attention : toutes les données de ce lancement seront perdues.")) {
            axios.delete(process.env.REACT_APP_UPA_API_HOST + 'orders/' + orderId, { headers: { Authorization: 'Bearer ' + token }})
                .then(() => {
                    toast.success('Lancements supprimé', toastOptions);
                    history.goBack();
                })
                .catch(err => {
                    toast.error(err.response.data.message, toastOptions);
                });
        }
    }

    const handleStart = () => {
        axios.put(process.env.REACT_APP_UPA_API_HOST + 'orders/' + orderId, { order: {...order, status:"progress" }}, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                toast.success('Lancement effectué', toastOptions);
                setOrder({...order, status:"progress" });
            })
            .catch(err => {
                toast.error(err.response.data.message, toastOptions);
            });
    }

    const handleFileDownload = (file) => {
        saveAs(process.env.REACT_APP_UPA_API_HOST + 'files/' + file.id + file.extension, file.fileName);
    }

    const popover = (
        <div className='user-options' onClick={popoverVisible ? hide : show}>
            { order.status === 'draft' ? (
                <div className='user-options-item launch' onClick={handleStart}>
                    <span>Démarrer</span>
                </div>
            ) : null }
            <Link to={'/orders/' + order.token} className='user-options-item link' target="_blank" rel="noreferrer">
                <span>Lien du fournisseur</span>
            </Link>
            <div className='user-options-item delete' onClick={handleDelete}>
                <span>Supprimer</span>
            </div>
        </div>
    );

    return (
        <div className='view-container'>
            <div className='view-header'>
                <div className='view-title'>
                    <h1>{order.name}</h1>
                    <span className='view-id'>#{order.id}</span>
                </div>
                <div className='view-buttons'>
                    <button className='main-button return-button margin-right' onClick={() => history.goBack()} />
                    { order.status === "archived" ? null :
                        <Link to={ url + '/edit' }>
                            <button className='main-button blue-button edit'>Modifier</button>
                        </Link>
                    }
                    <Tippy content={popover} allowHTML={true} placement={'bottom-end'} offset={[0,5]} visible={popoverVisible} onClickOutside={hide} interactive={true} appendTo={'parent'}>
                        <button className='squared-button margin-left' onClick={popoverVisible ? hide : show} />
                    </Tippy>
                </div>
            </div>
            <div className='input-row-container'>
                <span className="input-label role">Statut</span>
                <CustomTag value={order.status} />
            </div>
            { order.status === 'waiting' && order.awaitingOrders.length > 0 ? (
                <div>
                    <span className='waiting-order-warning'>Cette commande sera lancée automatiquement lorsque toutes les commandes précédentes auront renseigné une date d'expédition définitive.</span>
                    <div className='input-orders-container'>
                        <span className="input-label order">Lancements attendus avant de démarrer ce lancement</span>
                        <CustomList entity={'orders/' + order.id + '/awaiting-orders'} columns={columns} isSelectable={false} isPaginable={false} strictPath='orders' />
                    </div>
                </div>
            ) : null }
            <div className='input-row-container'>
                <span className="input-label calendar">Date d'expédition</span>
                <div className='input-date-container'>
                    <span className='date-view'>{ order.deliveryDate ? moment(order.deliveryDate).format('DD/MM/YYYY') : 'Non renseignée' }</span>
                    { order.deliveryDate && order.deliveryConfirmed ? (<span className='waiting-order-warning margin-left'>Confirmée</span>) : null }
                </div>
            </div>
            { order.deliveryDate && !order.deliveryConfirmed ? (
                <span className='waiting-order-warning'>Le fournisseur n'a pas encore confirmé définitivement la date d'expédition.</span>
            ) : null }
            <div className='input-row-container'>
                <span className="input-label folder">Projet</span>
                <CustomTag value={order.project.name} />
            </div>
            <div className='input-row-container'>
                <span className="input-label provider">Fournisseur</span>
                <CustomTag value={order.sender.name} className={order.sender.type} />
            </div>
            <div className='input-row-container'>
                <span className="input-label location">Livraison chez</span>
                <CustomTag value={order.recipient.name} className={order.recipient.type} />
            </div>
            <div className='input-row-container'>
                <span className="input-label user">Contact(s)</span>
                { order.contacts && order.contacts.map((contact, index) => (
                    <CustomTag key={index} className='margin-right' value={contact.firstName + ' ' + contact.lastName} />
                ))}
            </div>
            { order.files.length > 0 && (
                <div>
                    <div className='input-row-container'>
                        <span className='input-label file'>Fichiers</span>
                    </div>
                    <div className='files-container'>
                        { order.files && order.files.map((file, index) => (
                            <div className='file-block' key={index}>
                                <div className='file-preview'>
                                    { file.fileType === "application/pdf" ? (
                                        <Document file={process.env.REACT_APP_UPA_API_HOST + 'files/' + file.id + file.extension}>
                                            <Page pageNumber={1} className='custom-page-file'/>
                                        </Document>
                                    ) : (
                                        <img src={process.env.REACT_APP_UPA_API_HOST + 'files/' + file.id + file.extension} alt='desc' />
                                    )}
                                </div>
                                <div className='file-info'>
                                    <span>{file.fileName}</span>
                                    <div className='file-buttons'>
                                        <a href={process.env.REACT_APP_UPA_API_HOST + 'files/' + file.id + file.extension} target='_blank' rel="noreferrer"><button className='file-button view' /></a>
                                        <button className='file-button download' onClick={() => handleFileDownload(file)} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <div className='input-row-container'>
                <span className="input-label reminder">Relances</span>
                <div className='input-date-container'>
                    <span className='date-view'>Tous les {order.frequency + ' ' + constants.all.find((e) => { return e.value === order.timeFrequency }).label }</span>
                </div>
            </div>
            <div className='input-textarea-container'>
                <span className='input-label pen'>Notes internes</span>
                <div className='textarea-view'>{order.internalNotes}</div>
            </div>
        </div>
    );
}

export default OrderView;