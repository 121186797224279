import React, {useContext, useState} from "react";
import {Link, Redirect, Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import mainLogo from "../../assets/images/main-logo.svg";
import toastOptions from "../../assets/constants/toast";
import Loader from "react-loader-spinner";
import AuthContext from "../../contexts/AuthContext";
import Projects from "./projects/Projects";
import '../../assets/styles/AdminPanel.css';
import Orders from "./orders/Orders";
import Customers from "./customers/Customers";
import Providers from "./providers/Providers";
import Settings from "./settings/Settings";
import Tippy from '@tippyjs/react';
import CustomSearchInput from "../custom/CustomSearchInput";
import axios from "axios";
import {toast} from "react-toastify";

const AdminPanel = () => {

    let { path } = useRouteMatch();
    const history = useHistory();
    const { user, token, setToken, setUser } = useContext(AuthContext);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const show = () => setPopoverVisible(true);
    const hide = () => setPopoverVisible(false);
    const [searchData, setSearchData] = useState([]);

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'data/searchbar', { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    let data = response.data;
                    data = data.map(row => { return {...row, searchBar: true }});
                    console.log(data);
                    setSearchData(data);
                } else {
                    toast.error('Client inconnu', toastOptions);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [token]);

    const handleDisconnect = () => {
        localStorage.clear();
        setToken(null);
        setUser(null);
    }

    const handleSearchbarSelect = (newValue) => {
        history.push('/admin/' + newValue.dataType + 's/' + newValue.id);
    }

    const popover = (
        <div className='user-options'>
            { user && user.role === 'admin' ? (
                <Link to={ path + '/settings'} className='menu-link' onClick={popoverVisible ? hide : show}>
                    <div className='user-options-item' >
                        <span>Paramètres</span>
                    </div>
                </Link>
            ) : null }
            <div className='user-options-item logout' onClick={handleDisconnect}>
                <span>Se déconnecter</span>
            </div>
        </div>
    );

    return (
        <div className='main-app'>
            <div className='main-menu'>
                <div className='app-logo'>
                    <Link to='/admin/projects'>
                        <img src={mainLogo} alt='main-logo' width='180'/>
                    </Link>
                </div>
                <div className='menu-container'>
                    <div className='relative'>
                        <Link to={ path + '/projects'} className='menu-link'>
                            <div className={'menu-item projects' + (window.location.href.replace(process.env.REACT_APP_UPA_INTERFACE_HOST, '').includes('projects') ? ' active' : '')}>
                                <span>Projets</span>
                            </div>
                        </Link>
                        <Link to={ path + '/projects/new'}>
                            <span className='new-button'>+</span>
                        </Link>
                    </div>
                    <div className='relative'>
                        <Link to={ path + '/orders'} className='menu-link'>
                            <div className={'menu-item orders' + (window.location.href.replace(process.env.REACT_APP_UPA_INTERFACE_HOST, '').includes('orders') ? ' active' : '')}>
                                <span>Lancements</span>
                            </div>
                        </Link>
                        <Link to={ path + '/orders/new'}>
                            <span className='new-button'>+</span>
                        </Link>
                    </div>
                    <div className='relative'>
                        <Link to={ path + '/customers'} className='menu-link'>
                            <div className={'menu-item customers' + (window.location.href.replace(process.env.REACT_APP_UPA_INTERFACE_HOST, '').includes('customers') ? ' active' : '')}>
                                <span>Clients</span>
                            </div>
                        </Link>
                        <Link to={ path + '/customers/new'}>
                            <span className='new-button'>+</span>
                        </Link>
                    </div>
                    <div className='relative'>
                        <Link to={ path + '/providers'} className='menu-link'>
                            <div className={'menu-item suppliers' + (window.location.href.replace(process.env.REACT_APP_UPA_INTERFACE_HOST, '').includes('providers') ? ' active' : '')}>
                                <span>Fournisseurs</span>
                            </div>
                        </Link>
                        <Link to={ path + '/providers/new'}>
                            <span className='new-button'>+</span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='main-app-right-part'>
                <div className='main-navbar'>
                    <div className='searchbar-container'>
                        <div className='searchbar-item'>
                            <div className='input-container'>
                                <CustomSearchInput placeholder={null} entity={searchData} onChange={handleSearchbarSelect} value={null} />
                            </div>
                        </div>
                    </div>
                    <div className='user-info-container'>
                        <Tippy content={popover}
                               allowHTML={true}
                               placement={'bottom-end'}
                               offset={[0,0]}
                               visible={popoverVisible}
                               onClickOutside={hide}
                               interactive={true}
                               appendTo={'parent'}>
                            <div className='user-item' onClick={popoverVisible ? hide : show}>
                                <span>{ user ? user.firstName + ' ' + user.lastName : (<Loader type="Oval" color="#2C95E8" height={25} width={20} />)}</span>
                            </div>
                        </Tippy>
                    </div>
                </div>
                <div className='main-router-content'>
                    <Switch>
                        <Route path={ path + '/projects'}>
                            <Projects />
                        </Route>
                        <Route path={ path + '/orders'}>
                            <Orders />
                        </Route>
                        <Route path={ path + '/customers'}>
                            <Customers />
                        </Route>
                        <Route path={ path + '/providers'}>
                            <Providers />
                        </Route>
                        { user && user.role === 'admin' ? (
                            <Route path={ path + '/settings'}>
                                <Settings />
                            </Route>
                        ) : null }
                        <Route path='/'>
                            <Redirect to={ path + '/projects'} />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    );
}

export default AdminPanel;
