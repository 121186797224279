import React, {useContext, useState} from 'react';
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import CustomTag from "../../custom/CustomTag";
import moment from "moment";
import CustomList from "../../custom/CustomList";
import CustomSearchInput from "../../custom/CustomSearchInput";
import DatePicker from "react-datepicker";
import toastOptions from "../../../assets/constants/toast";

const ProjectView = () => {

    const { projectId } = useParams();
    const [project, setProject] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const { token } = useContext(AuthContext);
    const history = useHistory();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [minDate] = useState(moment());

    const columns = React.useMemo(
        () => [
            {
                Header: 'Nom',
                accessor: 'name',
                isFiltered: true,
                icon: 'pen',
            },
            {
                Header: 'Fournisseur',
                accessor: 'sender',
                isFiltered: true,
                icon: 'provider',
                Cell: ({ cell: { value } }) => (<CustomTag value={value.type} label={value.name} />)
            },
            {
                Header: 'Statut',
                accessor: 'status',
                isFiltered: true,
                icon: 'role',
                Cell: ({ cell: { value } }) => (<CustomTag value={value} />)
            },
            {
                Header: "Date d'expédition",
                accessor: 'deliveryDate',
                isFiltered: true,
                icon: 'calendar',
                Cell: ({ cell: { value } }) => value ? (<span>{ moment(value).format('DD MMMM YYYY') }</span>) : (<span className='erased'>Non renseignée</span>)
            }
        ],
        []
    )

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'projects/' + projectId, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    console.log(response.data);
                    if(response.data.status === "archived") {
                        history.goBack();
                    } else {
                        setProject({
                            ...response.data, project_managers: response.data.project_managers.map(manager => { return {
                                ...manager,
                                label: manager.firstName + ' ' + manager.lastName,
                                value: manager.id
                            }})
                        });
                        setIsLoaded(true);
                    }
                } else {
                    toast.error('Projet inconnu', toastOptions);
                    history.push('/projects');
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [history, token, projectId]);

    const handleUpdate = () => {
        setButtonLoading(true);
        axios.put(process.env.REACT_APP_UPA_API_HOST + 'projects/' + projectId, { project }, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                console.log(response);
                setButtonLoading(false);
                toast.success("Le projet a bien été modifié", toastOptions);
                history.goBack();
            })
            .catch((err) => {
                toast.error(err.response.data.message, toastOptions);
                setButtonLoading(false);
            });
    }

    return isLoaded ? (
        <div className='view-container'>
            <div className='view-header'>
                <div className='header-input'>
                    <input className="name-input-required" type='text' placeholder='Nom du client' value={project.name} onChange={(e) => {setProject((project) => { return {...project, name:e.target.value}})}} />
                    <span className='view-id'>#{project.id}</span>
                </div>
                <div className='view-buttons'>
                    <button onClick={history.goBack} className='main-button without-icon warning-button'>Annuler</button>
                    <button className='main-button blue-button validate margin-left' onClick={handleUpdate} disabled={buttonLoading}>{ buttonLoading ? <Loader type="Oval" color="#FFFFFF" height={30} width={20} /> : 'Sauvegarder' }</button>
                </div>
            </div>
            <div className='input-row-container'>
                <span className="input-label role">Statut</span>
                <CustomTag value={project.status} />
            </div>
            <div className='input-row-container'>
                <span className="input-label customer">Client</span>
                <CustomTag value={project.customer.name} />
            </div>
            <div className='input-row-container'>
                <span className='input-label user'>Responsable(s) <span className='required'>*</span></span>
                <div className='input-container'>
                    <CustomSearchInput placeholder='Rechercher un responsable'
                                       entity='users'
                                       isMultiSearch={true}
                                       labelType='user'
                                       value={project.project_managers}
                                       onChange={(newValue) => { setProject((project) => { return { ...project, project_managers:newValue }}); }}
                    />
                </div>
            </div>
            <div className='input-row-container'>
                <span className='input-label calendar'>Date limite <span className='required'>*</span></span>
                <div className='input-date-container'>
                    <DatePicker dateFormat='dd/MM/Y'
                                onKeyDown={(e) => e.preventDefault()}
                                className='classic-input'
                                selected={moment(project.deliveryEstimation).toDate()}
                                onChange={(date) => setProject({...project, deliveryEstimation: moment(date).format('YYYY-MM-DD')})}
                                minDate={minDate.toDate()}
                    />
                </div>
            </div>
            <div className='input-orders-container'>
                <span className="input-label order">Lancements</span>
                <CustomList entity={'projects/' + projectId + '/orders'} columns={columns} isSelectable={false} isPaginable={false} strictPath='orders' />
            </div>
            <div className='input-textarea-container'>
                <span className='input-label pen'>Notes internes</span>
                <div className='input-textarea'>
                    <textarea placeholder='Ajoutez une note interne...' rows={6} value={project.internalNotes} onChange={(e) => {setProject({...project, internalNotes:e.target.value})}}/>
                </div>
                <span className='required'>* Champs obligatoires</span>
            </div>
        </div>
    ) : (<div className='loader-container'><Loader type="Oval" color="#2C95E8" height={30} width={30} /></div>)
}

export default ProjectView;