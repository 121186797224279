import React, {useContext, useEffect, useState} from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import CustomSearchInput from "../../custom/CustomSearchInput";
import {useForm} from "react-hook-form";
import axios from "axios";
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import {toast} from "react-toastify";
import AuthContext from "../../../contexts/AuthContext";
import Loader from "react-loader-spinner";
import right_arrow from "../../../assets/icons/arrow-small-right.svg"
import toastOptions from "../../../assets/constants/toast";

const OrderAdd = () => {

    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);
    const projectId = parseInt(queryParameters.get("projectId"));
    const [projects, setProjects] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const history = useHistory();
    const { token } = useContext(AuthContext);
    const [order, setOrder] = useState({
        name: '',
        projectId: projectId ? projectId : null,
        senderId: 0,
        message: '',
        contacts: [],
        files: [],
        frequency: 3,
        timeFrequency: 'days',
        internalNotes: '',
        recipientId: 0,
        recipientType: ''
    });

    useEffect(() => {
        setIsLoaded(false);
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'projects' , { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                console.log(response.data);
                setProjects(response.data);
                setIsLoaded(true);
            })
            .catch((error) => {
                console.log(error);
                setIsLoaded(true);
            });
    }, [token]);

    const handleCreate = (status) => {
        setButtonLoading(true);
        order.files.forEach((file) => {
            file.fileName = file.name;
            file.fileType = file.type;
        });
        if(status === 'draft') {
            order.status = status
        }
        axios.post(process.env.REACT_APP_UPA_API_HOST + 'orders' , {order}, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                console.log(response);
                setButtonLoading(false);
                toast.success("Le lancement a bien été créé", toastOptions);
                history.goBack();
            })
            .catch((error) => {
                toast.error(error.response.data.message, toastOptions);
                setButtonLoading(false);
            });
    }

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setOrder((order) => {
                    file.image = reader.result;
                    let tab = order.files;
                    tab = [...tab, file];
                    return {...order, files: tab }
                })
            }
        }
    }

    const handleDeleteFile = (index) => {
        setOrder((order) => {
            const tab = [...order.files];
            tab.splice(index, 1);
            return {...order, files: tab}
        })
    }

    React.useEffect(() => {
        console.log(order);
    });

    return isLoaded ? (
        <div className='scrollable-page'>
            <div className='list-header'>
                <div className='header-input'>
                    <input {...register("orderName", { required: true })}
                           type='text'
                           className={(errors.orderName ? 'name-input-required' : '')}
                           placeholder='Nom du lancement'
                           value={order.name}
                           onChange={(e) => {setOrder((order) => {return {...order, name: e.target.value}})}} />
                </div>
                <div className='header-buttons'>
                    <button onClick={history.goBack} className='main-button without-icon warning-button'>Annuler</button>
                    <button className='main-button validate' onClick={() => handleCreate('draft')}>Sauvegarder</button>
                    <button className='main-button blue-button launch margin-left' onClick={handleSubmit(handleCreate)} disabled={buttonLoading}>{ buttonLoading ? <Loader type="Oval" color="#FFFFFF" height={30} width={20} /> : 'Démarrer le lancement' }</button>
                </div>
            </div>
            <div className='input-group without-top'>
                <div className='input-row-container'>
                    <span className='input-label folder'>Projet <span className='required'>*</span></span>
                    <div className='input-container'>
                        <CustomSearchInput placeholder='Rechercher un projet'
                                           entity={projects}
                                           value={projects.find(project => order.projectId === project.id)}
                                           onChange={(newValue) => { setOrder((order) => { return { ...order, projectId:newValue.value }})}}
                        />
                        <Link to='/admin/projects/new' className='squared-button blue-button' />
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className='input-label provider'>Fournisseur <span className='required'>*</span></span>
                    <div className='order-delivery-add-container'>
                        <div className='input-container'>
                            <CustomSearchInput placeholder='Rechercher un fournisseur'
                                               entity='providers'
                                               onChange={(newValue) => { setOrder((order) => { return { ...order, senderId:newValue.value, contacts: [] }}) }}
                            />
                        </div>
                        <div className='order-delivery-add-transition'>
                            <span>chez</span>
                            <img src={right_arrow} alt='right' width={24}/>
                        </div>
                        <div className='input-container'>
                            <CustomSearchInput placeholder='Rechercher un fournisseur/client'
                                               entity='orders/entities'
                                               onChange={(newValue) => { setOrder((order) => { return { ...order, recipientId:newValue.value, recipientType: newValue.entityType }}) }}
                            />
                        </div>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className='input-label user'>Contacts <span className='required'>*</span></span>
                    <div className='input-container'>
                        <CustomSearchInput placeholder='Rechercher un contact'
                                           entity={order.senderId > 0 ? 'providers/' + order.senderId + '/contacts' : []}
                                           labelType='user'
                                           value={order.contacts}
                                           isMultiSearch={true}
                                           onChange={(newValue) => { setOrder((order) => { return { ...order, contacts:newValue }}); }}
                        />
                    </div>
                </div>
                <div className='input-textarea-container'>
                    <span className='input-label pen'>Message pour le fournisseur <span className='required'>*</span></span>
                    <div className='input-textarea'>
                        <textarea placeholder='Saisissez votre message...' rows={6} value={order.message} onChange={(e) => {setOrder((order) => {return {...order, message:e.target.value}})}}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className='input-label file'>Fichiers</span>
                    <label htmlFor='file-add' className='main-button small-button blue-button'>Ajouter un fichier</label>
                    <input type='file' id='file-add' name='file-add' placeholder='Ajouter un fichier' onChange={(e) => handleFileUpload(e)}/>
                </div>
                <div className='files-container'>
                    { order.files && order.files.map((file, index) => (
                        <div className='file-block' key={index}>
                            <div className='file-preview'>
                                { file.type === "application/pdf" ? (
                                    <Document file={file.image}>
                                        <Page pageNumber={1} className='custom-page-file'/>
                                    </Document>
                                ) : (
                                    <img src={file.image} alt='desc' />
                                )}
                            </div>
                            <div className='file-info'>
                                <span>{file.name}</span>
                                <div className='file-buttons'>
                                    <button className='file-button delete' onClick={() => handleDeleteFile(index)} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='input-row-container'>
                    <span className='input-label reminder'>Relances</span>
                    <div className='frequency-container'>
                        <span>Tous les</span>
                        <input type='number' min={0} max={99} value={order.frequency} onChange={(e) => {setOrder((order) => {return {...order, frequency: e.target.value ? parseInt(e.target.value) : 0 }})}} />
                        <select value={order.timeFrequency} onChange={(e) => {setOrder((order) => {return {...order, timeFrequency: e.target.value}})}}>
                            <option value='days'>jours</option>
                            <option value='weeks'>semaines</option>
                            <option value='months'>mois</option>
                        </select>
                    </div>
                </div>
                <div className='input-textarea-container'>
                    <span className='input-label pen'>Notes internes</span>
                    <div className='input-textarea'>
                        <textarea placeholder='Ajoutez une note interne...' rows={6} value={order.internalNotes} onChange={(e) => {setOrder((order) => {return {...order, internalNotes: e.target.value}})}}/>
                    </div>
                    <span className='required'>* Champs obligatoires</span>
                </div>
            </div>
        </div>
    ) : <div className='loader-container'><Loader type="Oval" color="#2C95E8" height={30} width={30} /></div>;
}

export default OrderAdd;
