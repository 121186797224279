import React, {useContext, useState} from 'react';
import {Link, useHistory, useParams, useRouteMatch} from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import CustomTag from "../../custom/CustomTag";
import Tippy from "@tippyjs/react";
import toastOptions from "../../../assets/constants/toast";

const CustomerView = () => {

    const { customerId } = useParams();
    const [customer, setCustomer] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const { token } = useContext(AuthContext);
    const history = useHistory();
    let { url } = useRouteMatch();
    const [popoverVisible, setPopoverVisible] = useState(false);
    const show = () => setPopoverVisible(true);
    const hide = () => setPopoverVisible(false);

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'customers/' + customerId, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    console.log(response.data);
                    setCustomer(response.data);
                    setIsLoaded(true);
                } else {
                    toast.error('Client inconnu', toastOptions);
                    history.goBack();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [history, token, customerId]);

    const handleArchive = () => {
        if(customer && customer.status === "archived") {
            axios.put(process.env.REACT_APP_UPA_API_HOST + 'customers/' + customerId, { customer: {...customer, status:"active" }}, { headers: { Authorization: 'Bearer ' + token }})
                .then(() => {
                    toast.success('Client rétabli', toastOptions);
                    setCustomer({...customer, status:"active" });
                })
                .catch(err => {
                    toast.error(err.response.data.message, toastOptions);
                });
        } else {
            axios.put(process.env.REACT_APP_UPA_API_HOST + 'customers/' + customerId, { customer: {...customer, status:"archived" }}, { headers: { Authorization: 'Bearer ' + token }})
                .then(() => {
                    toast.success('Client archivé', toastOptions);
                    history.goBack();
                })
                .catch(err => {
                    toast.error(err.response.data.message, toastOptions);
                });
        }
    }

    const handleDelete = () => {
        if (window.confirm("Attention : toutes les données de ce client seront perdues.")) {
            axios.delete(process.env.REACT_APP_UPA_API_HOST + 'customers/' + customerId, { headers: { Authorization: 'Bearer ' + token }})
                .then(() => {
                    toast.success('Client archivé', toastOptions);
                    history.goBack();
                })
                .catch(err => {
                    toast.error(err.response.data.message, toastOptions);
                });
        }
    }

    const popover = (
        <div className='user-options' onClick={popoverVisible ? hide : show}>
            <div className='user-options-item archive' onClick={handleArchive}>
                <span>{customer && customer.status === "archived" ? 'Rétablir' : 'Archiver'}</span>
            </div>
            <div className='user-options-item delete' onClick={handleDelete}>
                <span>Supprimer</span>
            </div>
        </div>
    );

    return isLoaded ? (
        <div className='view-container'>
            <div className='view-header'>
                <div className='view-title'>
                    <h1>{customer.name}</h1>
                </div>
                <div className='view-buttons'>
                    <button className='main-button return-button margin-right' onClick={() => history.goBack()} />
                    { customer.status === "archived" ? null :
                        <Link to={ url + '/edit' }>
                            <button className='main-button blue-button edit'>Modifier</button>
                        </Link>
                    }
                    <Tippy content={popover}
                           allowHTML={true}
                           placement={'bottom-end'}
                           offset={[0,5]}
                           visible={popoverVisible}
                           onClickOutside={hide}
                           interactive={true}
                           appendTo={'parent'}>
                        <button className='squared-button margin-left' onClick={popoverVisible ? hide : show} />
                    </Tippy>
                </div>
            </div>
            <div className='input-row-container'>
                <span className="input-label role">Statut</span>
                <CustomTag value={customer.status} />
            </div>
            <div className='input-row-container'>
                <span className="input-label role">Type</span>
                <CustomTag value={customer.type} />
            </div>
            <div className='input-row-container'>
                <span className="input-label location">Adresse</span>
                <CustomTag value={customer.address} />
            </div>
            <div className='input-row-container align-columns'>
                <span className="input-label user">Contacts</span>
                { customer.contacts ? customer.contacts.map((contact, index) => (
                    <div className='contact-container full-width' key={index}>
                        <div className='contact-info'>
                            <span>{contact.firstName}</span>
                            <span>{contact.lastName}</span>
                            <span>{contact.email}</span>
                            <span>{contact.phone}</span>
                        </div>
                    </div>
                )) : (
                    <div className='no-value'>Aucun contact renseigné pour ce client.</div>
                )}
            </div>
        </div>
    ) : (<div className='loader-container'><Loader type="Oval" color="#2C95E8" height={30} width={30} /></div>)
}

export default CustomerView;