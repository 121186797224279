import React, {useContext, useState} from 'react';
import { useHistory } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Loader from "react-loader-spinner";
import CustomModal from "../../custom/CustomModal";
import up_classic from "../../../assets/images/main-logo-small.svg";
import up_school from "../../../assets/images/up-school-logo.svg";
import toastOptions from "../../../assets/constants/toast";

const CustomerAdd = () => {

    const [customer, setCustomer] = useState({ name: '', address: '', contacts: []});
    const [newContact, setNewContact] = useState({ type: 'customer', firstName: '', lastName: '', email: '', phone: '' });
    const [editedContact, setEditedContact] = useState({ type: 'customer', firstName: '', lastName: '', email: '', phone: '' });
    const [editedContactIndex, setEditedContactIndex] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const { token } = useContext(AuthContext);
    const history = useHistory();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { register: registerContact, handleSubmit: handleSubmitContact, formState: { errors: contactErrors } } = useForm();

    const handleCreate = () => {
        setButtonLoading(true);
        axios.post(process.env.REACT_APP_UPA_API_HOST + 'customers' , { customer }, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                console.log(response);
                setButtonLoading(false);
                toast.success("Le client a bien été ajouté", toastOptions);
                history.goBack();
            })
            .catch((error) => {
                toast.error(error.response.data.message, toastOptions);
                setButtonLoading(false);
            });
    }

    const handleEditModalShow = (isShow) => {
        if(!isShow) {
            setEditedContact({ type: 'customer', firstName: '', lastName: '', email: '', phone: '' });
            setEditModalShow(false);
        }
    }

    const handleShowUpdateContact = (index) => {
        setEditedContact(customer.contacts[index]);
        setEditedContactIndex(index);
        setEditModalShow(true);
    }

    const handleEditContact = () => {
        setCustomer((customer) => {
            let tab = [...customer.contacts];
            tab[editedContactIndex] = editedContact;
            return {...customer, contacts: tab }
        });
        setEditModalShow(false);
    }

    const handleDeleteContact = (index) => {
        setCustomer((customer) => {
            const tab = [...customer.contacts];
            tab.splice(index, 1);
            return {...customer, contacts: tab}
        });
    }

    const handleAddContact = () => {
        setCustomer((customer) => {
            let tab = customer.contacts;
            tab = [...tab, newContact];
            return {...customer, contacts: tab }
        });
        setNewContact({ type: 'customer', firstName: '', lastName: '', email: '', phone: ''});
        setModalShow(false);
    }

    return (
        <div>
            <div className='list-header'>
                <div className='header-input'>
                    <input {...register("customerName", { required: true })} type='text' className={(errors.customerName ? 'name-input-required' : '')} placeholder='Nom du client' value={customer.name} onChange={(e) => { setCustomer((customer) => { return { ...customer, name: e.target.value }}) }} />
                </div>
                <div className='header-buttons'>
                    <button onClick={history.goBack} className='main-button without-icon warning-button'>Annuler</button>
                    <button className='main-button blue-button plus margin-left' onClick={handleSubmit(handleCreate)} disabled={buttonLoading}>{ buttonLoading ? <Loader type="Oval" color="#FFFFFF" height={30} width={20} /> : 'Ajouter le client' }</button>
                </div>
            </div>
            <div className='input-group without-top'>
                <div className='input-row-container align-columns'>
                    <span className="input-label type">Type de client <span className='required'>*</span></span>
                    <div className='provider-container customer-type'>
                        <div className={'provider-block logistics' + (customer.type === 'uniforme-prestige' ? ' checked' : '')} onClick={() => setCustomer(() => { return {...customer, type: 'uniforme-prestige'}})}>
                            <div className='provider-check'/>
                            <div className='provider-content '>
                                <img src={up_classic} alt='material' />
                                <span>Uniforme Prestige</span>
                            </div>
                        </div>
                        <div className={'provider-block production' + (customer.type === 'uniforme-prestige-school' ? ' checked' : '')} onClick={() => setCustomer(() => { return {...customer, type: 'uniforme-prestige-school'}})}>
                            <div className='provider-check'/>
                            <div className='provider-content'>
                                <img src={up_school} alt='material' />
                                <span>UP School</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label location">Adresse <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={customer.address} className='classic-input' placeholder='Entrez une adresse' onChange={(e) => {
                            setCustomer((customer) => { return { ...customer, address:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container align-columns'>
                    <span className="input-label user">Contacts <span className='required'>*</span></span>
                    { customer.contacts.map((contact, index) => (
                        <div className='contact-container' key={index}>
                            <div className='contact-info'>
                                <span>{contact.firstName}</span>
                                <span>{contact.lastName}</span>
                                <span>{contact.email}</span>
                                <span>{contact.phone}</span>
                            </div>
                            <div className='contact-update'>
                                <button className='update-icon-button' onClick={() => handleShowUpdateContact(index)} />
                            </div>
                            <div className='contact-delete'>
                                <button className='delete-icon-button' onClick={() => handleDeleteContact(index)} />
                            </div>
                        </div>
                    ))}
                    <div className='small-button-container'>
                        <button className='main-button small-button blue-button' onClick={() => setModalShow(true)}>Ajouter un contact</button>
                    </div>
                </div>
            </div>
            <CustomModal title={'Nouveau contact'} setShow={setModalShow} show={modalShow}>
                <div className='input-row-container'>
                    <span className="input-label user">Prénom <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={newContact.firstName} className={'classic-input ' + (contactErrors.firstName ? 'input-required' : '')} placeholder='Entrez un prénom' onChange={(e) => {
                            setNewContact((contact) => { return { ...contact, firstName:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label user">Nom <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={newContact.lastName} className={'classic-input ' + (contactErrors.lastName ? 'input-required' : '')} placeholder='Entrez un nom' {...registerContact("lastName", { required: true })} onChange={(e) => {
                            setNewContact((contact) => { return { ...contact, lastName:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label email">Email <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={newContact.email} className={'classic-input ' + (contactErrors.email ? 'input-required' : '')} placeholder='Entrez un email' {...registerContact("email", { required: true })} onChange={(e) => {
                            setNewContact((contact) => { return { ...contact, email:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label phone">Téléphone <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={newContact.phone} className={'classic-input ' + (contactErrors.phone ? 'input-required' : '')} placeholder='Entrez un téléphone' {...registerContact("phone", { required: true })} onChange={(e) => {
                            setNewContact((contact) => { return { ...contact, phone:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='submit-right-container'>
                    <button className="main-button blue-button plus" onClick={handleSubmitContact(handleAddContact)}>Ajouter le contact</button>
                </div>
            </CustomModal>
            <CustomModal title={'Éditer le contact'} setShow={handleEditModalShow} show={editModalShow}>
                <div className='input-row-container'>
                    <span className="input-label user">Prénom <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={editedContact.firstName} className='classic-input' placeholder='Entrez un prénom' onChange={(e) => {
                            setEditedContact((contact) => { return { ...contact, firstName:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label user">Nom <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={editedContact.lastName} className='classic-input' placeholder='Entrez un nom' onChange={(e) => {
                            setEditedContact((contact) => { return { ...contact, lastName:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label email">Email <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={editedContact.email} className='classic-input' placeholder='Entrez un email' onChange={(e) => {
                            setEditedContact((contact) => { return { ...contact, email:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label phone">Téléphone <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={editedContact.phone} className='classic-input' placeholder='Entrez un téléphone' onChange={(e) => {
                            setEditedContact((contact) => { return { ...contact, phone:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='submit-right-container'>
                    <button className="main-button blue-button validate" onClick={handleEditContact}>Modifier le contact</button>
                </div>
            </CustomModal>
        </div>
    )
}

export default CustomerAdd;