import React, {useContext, useState} from "react";
import axios from "axios";
import constants from "../../assets/constants/constants";
import AuthContext from "../../contexts/AuthContext";
import Select from "react-select";
import CustomOption from "./CustomOption";

const CustomSearchInput = ({ placeholder, entity, isMultiSearch, labelType, onChange, className, value }) => {

    const [items, setItems] = useState([]);
    const { token } = useContext(AuthContext);

    React.useEffect(() => {
        if(typeof entity === "string") {
            axios.get(process.env.REACT_APP_UPA_API_HOST + entity, { headers: { Authorization: 'Bearer ' + token }})
                .then((response) => {
                    setItems(formatItems(response.data));
                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            // In this case, entity MUST be an array
            setItems(formatItems(entity));
        }
    }, [entity, token, value]);

    const formatItems = (items) => {
        if(items) {
            items.map((item) => {
                item.value = item.id;
                switch (labelType) {
                    case 'user':
                        item.value = item.id ? item.id : item.lastName;
                        item.label = item.firstName + ' ' + item.lastName;
                        break;
                    case 'address':
                        item.label = item.address;
                        item.value = item.id;
                        break;
                    case 'filter':
                        const foundConstant = constants.all.find((e) => { return e.value === item.label });
                        item.label = foundConstant ? foundConstant.label : item.label;
                        break;
                    default:
                        item.label = item.name;
                        break;
                }
                return item;
            });
            return items;
        }
        return null;
    }

    return (
        <Select
            isMulti={isMultiSearch}
            name="colors"
            isRequired={true}
            // menuIsOpen={true} // DEBUG
            options={items}
            onChange={onChange}
            value={value}
            className={ isMultiSearch ? "basic-multi-select multi-search-input " : "basic-single single-search-input " + className}
            classNamePrefix="select"
            placeholder={placeholder}
            components={{ Option: CustomOption }}
        />
    )
}

export default CustomSearchInput;