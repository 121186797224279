import React, {useContext, useState} from "react";
import {Link, useRouteMatch} from "react-router-dom";
import CustomList from "../../custom/CustomList";
import AuthContext from "../../../contexts/AuthContext";
import {toast} from "react-toastify";
import axios from "axios";
import CustomTag from "../../custom/CustomTag";
import moment from 'moment';
import CustomFilter from "../../custom/CustomFilter";
import CustomDownloader from "../../custom/CustomDownloader";
import toastOptions from "../../../assets/constants/toast";

const OrderList = () => {

    let { path } = useRouteMatch();
    const [selectedColumns, setSelectedColumns] = useState(0);
    const [selectedIds, setSelectedIds] = useState([]);
    const [data, setData] = useState(null);
    const [filterOpen, setFilterOpen] = useState(false);
    const [activeFilters, setActiveFilters] = useState([]);
    const [refreshCounter, setRefreshCounter] = useState(0);
    const { token } = useContext(AuthContext);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Nom',
                accessor: 'name',
                isFiltered: true,
                icon: 'pen',
            },
            {
                Header: 'ID',
                accessor: 'id',
                isFiltered: true,
                icon: 'id'
            },
            {
                Header: 'Projet',
                accessor: 'project.name',
                isFiltered: true,
                icon: 'project'
            },
            {
                Header: 'Fournisseur',
                accessor: 'sender',
                downloadAccessor: 'sender.name',
                isFiltered: true,
                icon: 'provider',
                Cell: ({ cell: { value } }) => (<CustomTag value={value.type} label={value.name} />)
            },
            {
                Header: 'Statut',
                accessor: 'status',
                isFiltered: true,
                icon: 'role',
                Cell: ({ cell: { value } }) => (<CustomTag value={value} />)
            },
            {
                Header: "Date d'expédition",
                accessor: 'deliveryDate',
                isFiltered: true,
                icon: 'calendar',
                Cell: ({ cell: { value } }) => value ? (<span>{ moment(value).format('DD MMMM YYYY') }</span>) : (<span className='erased'>Non renseignée</span>)
            }
        ],
        []
    )

    const handleBulkDelete = () => {
        if (window.confirm("Attention : vous êtes sur le point de supprimer plusieurs lancements")) {
            axios.post(process.env.REACT_APP_UPA_API_HOST + 'orders/bulk-delete', { selectedIds }, { headers: { Authorization: 'Bearer ' + token }})
                .then(() => {
                    toast.success('Lancements supprimés', toastOptions);
                    setRefreshCounter(refreshCounter + 1);
                })
                .catch(err => {
                    toast.error(err.response.data.message, toastOptions);
                });
        }
    }

    return (
        <div className='page-content'>
            <div className='list-header'>
                <div className='header-title orders'>
                    <h1>Les lancements</h1>
                </div>
                <div className='header-buttons'>
                    { selectedColumns === 0 ?
                        <div>
                            <Link to={path + '/new'}>
                                <button className='main-button blue-button plus'>Créer un lancement</button>
                            </Link>
                            <button className='main-button filter' onClick={() => setFilterOpen(true)} >Filtrer</button>
                            <CustomDownloader data={data} columns={columns} filename={'lancements.csv'} />
                        </div>
                        :
                        <div>
                            <button className='main-button warning-button delete' onClick={handleBulkDelete}>Supprimer</button>
                        </div>
                    }
                </div>
                <CustomFilter columns={columns} data={data} setData={setData} setOpen={setFilterOpen} open={filterOpen} setActiveFilters={setActiveFilters} />
            </div>
            <CustomList entity='orders' columns={columns} setSelectedColumns={setSelectedColumns} setSelectedIds={setSelectedIds} refreshData={refreshCounter} setData={setData} activeFilters={activeFilters} />
        </div>
    );
}

export default OrderList;
