import React, { useState } from "react";
import Loader from "react-loader-spinner";
import CustomSearchInput from "./CustomSearchInput";
import DatePicker from "react-datepicker";
import moment from "moment";

const CustomFilter = ({ columns, data, open, setOpen, setActiveFilters }) => {

    const [activeColumns, setActiveColumns] = useState(columns);

    React.useEffect(() => {
        if(data && activeColumns && data.length > 0) {
            activeColumns.forEach(column => {
                let values = []
                data.forEach(row => {
                    switch (true) {
                        case column.accessor === 'project.name' || column.accessor === 'customer.name' || column.accessor === 'provider' || column.accessor === 'sender':
                            if (!values.find(value => value.value === row[column.accessor.split('.')[0]].name)) {
                                values.push({
                                    id: row[column.accessor.split('.')[0]].name,
                                    value: row[column.accessor.split('.')[0]].name,
                                    label: row[column.accessor.split('.')[0]].name
                                });
                            }
                            break;
                        case column.accessor === 'project_managers.0':
                            const fullName = row.project_managers[0].firstName + ' ' + row.project_managers[0].lastName;
                            if (!values.find(value => value.value === fullName)) {
                                values.push({
                                    id: fullName,
                                    value: fullName,
                                    label: fullName
                                });
                            }
                            break;
                        default:
                            if(!values.find(value => value.value === row[column.accessor])) {
                                values.push({
                                    id: row[column.accessor],
                                    value: row[column.accessor],
                                    label: row[column.accessor]
                                });
                            }
                            break;
                    }
                })
                column.data = values;
            });
        }
    }, [data, activeColumns]);

    const handleFilter = (activeColumns) => {
        setActiveFilters(activeColumns);
        setOpen(false);
    }

    const flushFilters = () => {
        const flushedColumns = activeColumns.map(column => { return {...column, selectedValues: [], startDate: null, stopDate: null }})
        setActiveColumns(flushedColumns);
        handleFilter(flushedColumns);
    }

    const updateSelectedValues = (index, selectedValues) => {
        let newArr = [...activeColumns];
        newArr[index].selectedValues = selectedValues;
        setActiveColumns(newArr);
    }

    const updateStartDate = (index, date) => {
        let newArr = [...activeColumns];
        newArr[index].startDate = date;
        setActiveColumns(newArr);
    }

    const updateStopDate = (index, date) => {
        let newArr = [...activeColumns];
        newArr[index].stopDate = date;
        setActiveColumns(newArr);
    }

    return (
        <div className={ open ? 'custom-filter' : 'custom-filter closed'} >
            <div className="custom-filter-header">
                <button className="close-button" onClick={() => setOpen(false)} />
                <h1>Filtrer par</h1>
            </div>
            { data ? (
                <div className="filter-input-container">
                    { activeColumns.map((column, index) => column.isFiltered ? (
                        <div className="filter-input" key={index}>
                            <span className='filter-title'>{column.Header}</span>
                            { column.icon === 'calendar' ? (
                                <div className='input-date-container'>
                                    <DatePicker dateFormat='dd/MM/Y'
                                                onKeyDown={(e) => e.preventDefault()}
                                                className='classic-input'
                                                selected={column.startDate && column.startDate.toDate()}
                                                onChange={(date) => updateStartDate(index, moment(date))}
                                    />
                                    <div className='stop-date'>
                                        <DatePicker dateFormat='dd/MM/Y'
                                                    onKeyDown={(e) => e.preventDefault()}
                                                    className='classic-input'
                                                    selected={column.stopDate && column.stopDate.toDate()}
                                                    onChange={(date) => updateStopDate(index, moment(date))}
                                                    minDate={column.startDate && column.startDate.toDate()}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className='input-container'>
                                    <CustomSearchInput placeholder={column.Header}
                                                       entity={column.data}
                                                       value={column.selectedValues}
                                                       onChange={(newValue) => updateSelectedValues(index, newValue)}
                                                       labelType='filter'
                                                       isMultiSearch={true}
                                    />
                                </div>
                            )}
                        </div>
                    ) : null )}
                </div>
            ) : (
                <div className='loader-container'><Loader type="Oval" color="#2C95E8" height={30} width={30} /></div>
            )}
            <div className="bottom-buttons">
                <button className="main-button blue-button without-icon" onClick={() => handleFilter(activeColumns)}>Filtrer</button>
                <button className="transparent-button" onClick={flushFilters}>Réinitialiser</button>
            </div>
        </div>
    );
}

export default CustomFilter;