import React, {useContext, useState} from 'react';
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import Loader from "react-loader-spinner";

const OrderStatusBar = ({ projectId }) => {

    const { token } = useContext(AuthContext);
    const [project, setProject] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'projects/' + projectId, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    console.log(response.data);
                    setProject(response.data);
                    setIsLoaded(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [projectId, token]);

    const getStatus = (orders) => {
        orders = orders.filter(order => order.status !== 'draft' && order.status !== 'archived');
        if(orders.length === 0) return null;
        if(orders.find(order => order.status === 'difficulty')) return 'difficulty';
        if(orders.find(order => order.status === 'progress' || order.status === 'waiting')) return 'in-progress';
        return 'delivered';
    }

    return isLoaded ? (
        <div className="order-status-bar-container">
            <div className={"order-status-item " + getStatus(project.orders.filter(order => order.sender.type === 'fabrics'))}>
                <span className="order-status-tip" />
                <span>Tissus</span>
            </div>
            <div className={"order-status-item " + getStatus(project.orders.filter(order => order.sender.type === 'production'))}>
                <span className="order-status-tip" />
                <span>Production</span>
            </div>
            <div className={"order-status-item " + getStatus(project.orders.filter(order => order.sender.type === 'logistics'))}>
                <span className="order-status-tip" />
                <span>Logistique</span>
            </div>
        </div>
    ) : (<Loader type="Oval" color="#2C95E8" height={20} width={20} />)
}

export default OrderStatusBar;