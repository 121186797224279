import React from "react";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import ProviderList from "./ProviderList";
import ProviderAdd from "./ProviderAdd";
import ProviderView from "./ProviderView";
import ProviderEdit from "./ProviderEdit";

const Providers = () => {

    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <ProviderList />
            </Route>
            <Route path={path + '/new'}>
                <ProviderAdd />
            </Route>
            <Route path={path + "/:providerId/edit"}>
                <ProviderEdit />
            </Route>
            <Route path={path + "/:providerId"}>
                <ProviderView />
            </Route>
            <Route path={ path + '*'}>
                <Redirect to={path} />
            </Route>
        </Switch>
    )
}

export default Providers;
