import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import {ToastContainer} from "react-toastify";
import 'moment/locale/fr';
import moment from "moment";

moment().locale('fr');

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <ToastContainer />
          <App />
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
