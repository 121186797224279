import React, {useRef, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import full_logo from "../../assets/images/main-logo-small.svg";
import calendar from "../../assets/icons/calendar-full--white.svg";
import not_found from "../../assets/images/order-404.svg";
import edit_button from "../../assets/icons/edit--white.svg";
import { validate as uuidValidate, version as uuidVersion } from 'uuid';
import Loader from "react-loader-spinner";
import axios from "axios";
import CustomAction from "../custom/CustomAction";
import {toast} from "react-toastify";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import CustomTag from "../custom/CustomTag";
import {Document, Page} from "react-pdf/dist/umd/entry.webpack";
import { saveAs } from "file-saver";
import moment from "moment";
import DatePicker from "react-datepicker";

const OrderPortal = () => {

    let reactCalendar = useRef();
    const { orderToken } = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const [deliveryEstimation, setDeliveryEstimation] = useState(null);
    const [isEditingDate, setIsEditingDate] = useState(false);
    const [minDate] = useState(moment());
    const [order, setOrder] = useState({});
    const history = useHistory();
    const messagesEndRef = useRef(null);
    const [message, setMessage] = useState('');
    const [refreshData, setRefreshData] = useState(0);

    React.useEffect(() => {
        if(orderToken && uuidValidateV4(orderToken)) {
            axios.get(process.env.REACT_APP_UPA_API_HOST + 'token/' + orderToken)
                .then((response) => {
                    if(response.data) {
                        console.log(response.data);
                        const newOrder = response.data;
                        newOrder.dateEditAvailable = newOrder.awaitingOrders.filter(order => order.deliveryDate && order.deliveryConfirmed).length === newOrder.awaitingOrders.length;
                        setOrder(newOrder);
                        setIsLoaded(true);
                        scrollToBottom();
                    } else {
                        history.push('/login');
                    }
                })
                .catch((error) => {
                    history.push('/login');
                    console.log(error);
                });
        } else {
            history.push('/login');
        }
    }, [refreshData, history, orderToken]);

    const handleSendMessage = () => {
        if(message !== '') {
            toast.promise(
                axios.post(process.env.REACT_APP_UPA_API_HOST + 'token/' + orderToken +  '/message', { message: {type: 'provider-message', content: message, orderId: order.id }}),
                {
                    error: 'Une erreur est survenue'
                }, {
                    position: "bottom-right",
                    autoClose: 2000,
                    pauseOnHover: false
                }
            ).then(() => {
                setMessage('');
                setRefreshData(refreshData + 1);
            });
        }
    }

    const handleSubmitDate = () => {
        toast.promise(
            axios.post(process.env.REACT_APP_UPA_API_HOST + 'token/' + orderToken +  '/date', {
                orderId: order.id,
                deliveryDate: deliveryEstimation.format('YYYY-MM-DD')
            }),
            {
                error: 'Une erreur est survenue',
                success: 'Merci ! La date a bien été prise en compte'
            }, {
                position: "bottom-right",
                autoClose: 2000,
                pauseOnHover: false
            }
        ).then(() => {
            handleResetDate();
            setRefreshData(refreshData + 1);
        });
    }

    const handleConfirmDate = () => {
        toast.promise(
            axios.post(process.env.REACT_APP_UPA_API_HOST + 'token/' + orderToken +  '/confirm-date', {}),
            {
                error: 'Une erreur est survenue',
                success: 'Merci ! La date finale a bien été prise en compte'
            }, {
                position: "bottom-right",
                autoClose: 2000,
                pauseOnHover: false
            }
        ).then(() => {
            setRefreshData(refreshData + 1);
        });
    }

    const handleConfirmReception = (orderId) => {
        toast.promise(
            axios.post(process.env.REACT_APP_UPA_API_HOST + 'token/' + orderToken +  '/confirm-reception', {
                orderId: orderId
            }),
            {
                error: 'Une erreur est survenue',
                success: 'Merci ! La réception a bien été prise en compte'
            }, {
                position: "bottom-right",
                autoClose: 2000,
                pauseOnHover: false
            }
        ).then(() => {
            handleResetDate();
            setRefreshData(refreshData + 1);
        });
    }

    const scrollToBottom = () => {
        if(messagesEndRef.current) {
            smoothScrollIntoView(messagesEndRef.current, {
                scrollMode: 'if-needed',
                block: 'nearest',
                behavior: 'smooth',
                inline: 'nearest',
            })
        }
    }

    const uuidValidateV4 = (uuid) => { return uuidValidate(uuid) && uuidVersion(uuid) === 4 }
    const handleFileDownload = (file) => saveAs(process.env.REACT_APP_UPA_API_HOST + 'files/' + file.id + file.extension, file.fileName);
    const openCalendar = () => reactCalendar.setOpen(true);
    const handleSelectDate = (date) => setDeliveryEstimation(moment(date));
    const handleEditDate = () => setIsEditingDate(true);
    React.useEffect(() => { scrollToBottom() }, [order]);

    const handleResetDate = () => {
        setDeliveryEstimation(null);
        setIsEditingDate(false);
    }

    return isLoaded ? (
        <div className='order-portal'>
            <div className='portal-header'>
                <div className='portal-title'>
                    <img src={full_logo} alt='portal-logo'/>
                    <div>
                        <h1>{order.name}</h1>
                        <span>#{order.id}</span>
                    </div>
                </div>
                <a href='tel:01 40 50 71 55' className='portal-phone'>
                    <span>01 40 50 71 55</span>
                </a>
            </div>
            { order.status !== 'draft' && order.status !== 'archived' ? (
                <div className='portal-content'>
                    <div className='left-container scrollable-page'>
                        <div className='view-container'>
                            <div className='view-header'>
                                <div className='view-title'>
                                    <h1 className='order-portal-welcome'>Bienvenue {order.sender.name}</h1>
                                </div>
                            </div>
                            { order.sender.type !== 'fabrics' ? (
                                <div className='waiting-orders-container'>
                                    <p>Avez vous reçu les commandes suivantes ?</p>
                                    <div className='waiting-orders-block'>
                                        { order.awaitingOrders.map((awaitingOrder, index) => awaitingOrder.deliveryDate && awaitingOrder.deliveryConfirmed ? (
                                            <div className='waiting-order' key={index}>
                                                <span className='provider-name'>{awaitingOrder.sender.name}</span>
                                                <span>Expédition le {moment(awaitingOrder.deliveryDate).format('DD MMMM YYYY')}</span>
                                                { awaitingOrder.received ? (
                                                    <button className='main-button green-button validate'>Réception confirmée</button>
                                                ) : (
                                                    <button className='order-receipt-confirm' onClick={() => handleConfirmReception(awaitingOrder.id)}>Confirmer la réception</button>
                                                )}
                                            </div>
                                        ) : (
                                            <div className='waiting-order disabled' key={index}>
                                                <span className='provider-name'>{awaitingOrder.sender.name}</span>
                                                <span className='no-border'>En attente</span>
                                            </div>
                                        ))}
                                    </div>
                                    <span className='waiting-order-warning'>Si vous n’avez pas reçu les commandes, veuillez-nous le faire part dans le fil de discussion.</span>
                                </div>
                            ) : null }
                            <div className='delivery-date-container'>
                                <p className='delivery-date-title'>Informations d'expédition</p>
                                { !order.deliveryDate ? (<p className='warning-delivery'>{ order.dateEditAvailable ? 'Veuillez indiquer une estimation de la date d’expédition du lancement' : 'Vous pourrez estimer une date d\'expédition une fois que toutes les commandes ci-dessus auront renseigné une date d\'expédition.' }</p>) : null }
                                <div className='delivery-container input-date-container'>
                                    { !order.deliveryDate || isEditingDate ? (
                                        <div className='datepicker-block'>
                                            <img src={calendar} alt='date-picker' className="datepicker-image" onClick={openCalendar} />
                                            <DatePicker dateFormat='dd/MM/Y'
                                                        onKeyDown={(e) => e.preventDefault()}
                                                        disabled={!order.dateEditAvailable}
                                                        ref={(c) => reactCalendar = c}
                                                        className='classic-input estimation-button'
                                                        placeholderText='Estimer une date'
                                                        selected={ deliveryEstimation ? deliveryEstimation.toDate() : null }
                                                        onChange={(date) => handleSelectDate(date)}
                                                        minDate={minDate.toDate()}
                                            />
                                        </div>
                                    ) : (
                                        <div className='delivery-container'>
                                            <div className='datepicker-block'>
                                                <div className='estimated-date'>
                                                    { !order.deliveryConfirmed ? (<img src={edit_button} alt='edit-button' className='estimated-date-edit' width={16} onClick={handleEditDate}/>) : null }
                                                    <small>{ !order.deliveryConfirmed ? 'Estimée le' : 'Confirmée pour le' }</small>
                                                    <h1>{moment(order.deliveryDate).format('DD')}</h1>
                                                    <h4>{moment(order.deliveryDate).format('MMMM YYYY')}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className='address-block'>
                                        <span className='delivery'>Livraison chez</span>
                                        <span className='provider-title'>{ order.recipient.name }</span>
                                        <span className='provider-address'>{ order.recipient.address }</span>
                                    </div>
                                </div>
                                { deliveryEstimation || isEditingDate ? (
                                    <div className='date-edit-buttons'>
                                        { deliveryEstimation ? (<button className="datepicker-validate" onClick={handleSubmitDate}>Valider</button>) : null }
                                        <button className="datepicker-delete" onClick={handleResetDate}>Annuler</button>
                                    </div>
                                ) : null }
                            </div>
                            { order.deliveryDate && moment(order.deliveryDate).diff(moment(moment().format('YYYY-MM-DD')), 'days') <= 14 && !order.deliveryConfirmed ? (
                                <div className='waiting-order final-delivery-confirm'>
                                    <span>Confirmez-vous définitivement la date d'expédition ?</span>
                                    <div className='buttons'>
                                        <button className='main-button green-button validate' onClick={handleConfirmDate}>Oui</button>
                                    </div>
                                </div>
                            ) : null }
                            <div className='input-row-container margin-top-30'>
                                <span className="input-label folder">Projet</span>
                                <CustomTag value={order.project.name} />
                            </div>
                            <div className='input-row-container'>
                                <span className="input-label user">Responsable(s)</span>
                                { order.project.project_managers && order.project.project_managers.map((user, index) => (
                                    <CustomTag value={user.firstName + ' ' + user.lastName} className='margin-right' key={index} />
                                ))}
                            </div>
                            { order.files.length > 0 && (
                                <div>
                                    <div className='input-row-container'>
                                        <span className='input-label file'>Fichiers</span>
                                    </div>
                                    <div className='files-container'>
                                        { order.files && order.files.map((file, index) => (
                                            <div className='file-block' key={index}>
                                                <div className='file-preview'>
                                                    { file.fileType === "application/pdf" ? (
                                                        <Document file={process.env.REACT_APP_UPA_API_HOST + 'files/' + file.id + file.extension}>
                                                            <Page pageNumber={1} className='custom-page-file'/>
                                                        </Document>
                                                    ) : (
                                                        <img src={process.env.REACT_APP_UPA_API_HOST + 'files/' + file.id + file.extension} alt='desc' />
                                                    )}
                                                </div>
                                                <div className='file-info'>
                                                    <span>{file.fileName}</span>
                                                    <div className='file-buttons'>
                                                        <a href={process.env.REACT_APP_UPA_API_HOST + 'files/' + file.id + file.extension} target='_blank' rel="noreferrer"><button className='file-button view' /></a>
                                                        <button className='file-button download' onClick={() => handleFileDownload(file)} />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='right-container'>
                        <div className='scrollable-actions'>
                            { order.actions && order.actions.length > 0 && order.actions.map((action, index) => (
                                <CustomAction action={action} key={index} />
                            ))}
                            <div ref={messagesEndRef} className='custom-action' />
                        </div>
                        <div className='action-sender-container'>
                            <div className='action-sender-input'>
                                <textarea rows={2} value={message} onChange={(e) => setMessage(e.target.value)} placeholder='Écrivez un message à Uniforme Prestige...' />
                            </div>
                            <div className='action-sender-button'>
                                <button className='sender-button' onClick={handleSendMessage} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='portal-content flex-view'>
                    <div className='order-404'>
                        <img src={not_found} alt='not found' width={400}/>
                        <h1>Oups...</h1>
                        <p>Cette commande n'est pas disponible pour le moment. Merci de réessayer plus tard.</p>
                    </div>
                </div>
            )}
        </div>
    ) : (
        <div className='loader-container'><Loader type="Oval" color="#2C95E8" height={30} width={30} /></div>
    );
}

export default OrderPortal;