const constants = {}

constants.roles = [
    { value: 'sales', label: 'Commercial' },
    { value: 'designer', label: 'Styliste' },
    { value: 'admin', label: 'Administrateur' }
];

constants.customerTypes = [
    { value: 'uniforme-prestige', label: 'Uniforme Prestige' },
    { value: 'uniforme-prestige-school', label: 'Uniforme Prestige School' }
]

constants.status = [
    { value: 'active', label: 'Actif' },
    { value: 'inactive', label: 'Inactif' },
    { value: 'archived', label: 'Archivé' },
    { value: 'progress', label: 'En cours' },
    { value: 'archived', label: 'Archivé' },
    { value: 'draft', label: 'Non commencé' },
    { value: 'waiting', label: 'En attente' },
    { value: 'closed', label: 'Terminé' },
    { value: 'difficulty', label: 'En difficulté' }
];

constants.types = [
    { value: 'fabrics', label: 'Tissus' },
    { value: 'production', label: 'Production' },
    { value: 'logistics', label: 'Logistique' }
]

constants.frequencies = [
    { value: 'days', label: 'jours' },
    { value: 'weeks', label: 'semaines' },
    { value: 'months', label: 'mois' }
]

constants.dataTypes = [
    { value: 'order', label: 'Lancement' },
    { value: 'project', label: 'Projet' },
    { value: 'customer', label: 'Client' },
    { value: 'provider', label: 'Fournisseur' }
]

constants.all = constants.roles.concat(constants.status);
constants.all = constants.all.concat(constants.types);
constants.all = constants.all.concat(constants.frequencies);
constants.all = constants.all.concat(constants.customerTypes);
constants.all = constants.all.concat(constants.dataTypes);

export default constants;