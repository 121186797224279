import React from "react";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import ProjectList from "./ProjectList";
import ProjectAdd from "./ProjectAdd";
import ProjectView from "./ProjectView";
import ProjectEditOld from "./ProjectEdit";

const Projects = () => {

    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <ProjectList />
            </Route>
            <Route path={path + '/new'}>
                <ProjectAdd />
            </Route>
            <Route path={path + "/:projectId/edit"}>
                <ProjectEditOld />
            </Route>
            <Route path={path + "/:projectId"}>
                <ProjectView />
            </Route>
            <Route path={ path + '*'}>
                <Redirect to={path} />
            </Route>
        </Switch>
    );
}

export default Projects;
