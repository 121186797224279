import React, {useContext, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import CustomSearchInput from "../../custom/CustomSearchInput";
import DatePicker from "react-datepicker";
import {useForm} from "react-hook-form";
import moment from "moment";
import axios from "axios";
import {toast} from "react-toastify";
import AuthContext from "../../../contexts/AuthContext";
import Loader from "react-loader-spinner";
import toastOptions from "../../../assets/constants/toast";

const ProjectAdd = () => {

    const [projectName, setProjectName] = useState("");
    const [buttonLoading, setButtonLoading] = useState(false);
    const [deliveryEstimation, setDeliveryEstimation] = useState(moment());
    const [minDate] = useState(moment());
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [internalNotes, setInternalNotes] = useState("");
    const [clientError] = useState("");
    const [userError] = useState("");
    const { register, handleSubmit, formState: { errors } } = useForm();
    const history = useHistory();
    const { token } = useContext(AuthContext);
    const project = {
        name: projectName,
        customerId: selectedCustomer,
        project_managers: selectedUsers,
        deliveryEstimation: deliveryEstimation.format('YYYY-MM-DD'),
        internalNotes: internalNotes
    }

    const handleCreate = () => {
        setButtonLoading(true);
        axios.post(process.env.REACT_APP_UPA_API_HOST + 'projects' , {project}, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                console.log(response);
                setButtonLoading(false);
                toast.success("Le projet a bien été créé", toastOptions);
                history.goBack();
            })
            .catch((error) => {
                toast.error(error.response.data.message, toastOptions);
                setButtonLoading(false);
            });
    }

    React.useEffect(() => {
        console.log(project);
    });

    const handleCustomerSelect = (client) => {
        setSelectedCustomer(client.id);
    }

    const handleUserSelect = (users) => {
        setSelectedUsers(users.map((user) => {
            return user.id;
        }));
        console.log(users);
    }

    return (
        <div>
            <div className='list-header'>
                <div className='header-input'>
                    <input {...register("projectName", { required: true })} type='text' className={(errors.projectName ? 'name-input-required' : '')} placeholder='Nom du projet' value={projectName} onChange={(e) => {setProjectName(e.target.value)}} />
                </div>
                <div className='header-buttons'>
                    <button onClick={history.goBack} className='main-button without-icon warning-button'>Annuler</button>
                    <button className='main-button blue-button plus margin-left' onClick={handleSubmit(handleCreate)} disabled={buttonLoading}>{ buttonLoading ? <Loader type="Oval" color="#FFFFFF" height={30} width={20} /> : 'Créer le projet' }</button>
                </div>
            </div>
            <div className='input-group without-top'>
                <div className='input-row-container'>
                    <span className='input-label customer'>Client <span className='required'>*</span></span>
                    <div className='input-container'>
                        <CustomSearchInput placeholder='Rechercher un client'
                                           entity='customers'
                                           isMultiSearch={false}
                                           className={clientError}
                                           onChange={handleCustomerSelect}
                        />
                        <Link to='/admin/orders/new'>
                            <button className='squared-button blue-button' />
                        </Link>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className='input-label user'>Responsable(s) <span className='required'>*</span></span>
                    <div className='input-container'>
                        <CustomSearchInput placeholder='Rechercher un responsable'
                                           entity='users'
                                           isMultiSearch={true}
                                           className={userError}
                                           labelType='user'
                                           onChange={handleUserSelect}
                        />
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className='input-label calendar'>Date limite <span className='required'>*</span></span>
                    <div className='input-date-container'>
                        <DatePicker dateFormat='dd/MM/Y'
                                    onKeyDown={(e) => e.preventDefault()}
                                    className='classic-input'
                                    selected={deliveryEstimation.toDate()}
                                    onChange={(date) => setDeliveryEstimation(moment(date))}
                                    minDate={minDate.toDate()}
                        />
                    </div>
                </div>
                <div className='input-textarea-container'>
                    <span className='input-label pen'>Notes internes</span>
                    <div className='input-textarea'>
                        <textarea placeholder='Ajoutez une note interne...' rows={6} value={internalNotes} onChange={(e) => {setInternalNotes(e.target.value)}}/>
                    </div>
                    <span className='required'>* Champs obligatoires</span>
                </div>
            </div>
        </div>
    );
}

export default ProjectAdd;
