import React, {useContext, useRef, useState} from 'react';
import {Redirect, Route, Switch, useHistory, useParams, useRouteMatch} from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed'
import CustomAction from "../../custom/CustomAction";
import OrderView from "./OrderView";
import OrderEdit from "./OrderEdit";
import toastOptions from "../../../assets/constants/toast";

const OrderContainer = () => {

    let { path } = useRouteMatch();
    const { orderId } = useParams();
    const [order, setOrder] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [message, setMessage] = useState('');
    const [refreshData, setRefreshData] = useState(0);
    const { token } = useContext(AuthContext);
    const history = useHistory();
    const messagesEndRef = useRef(null);

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'orders/' + orderId, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    console.log(response.data);
                    if(response.data.contacts && response.data.contacts.length > 0) {
                        response.data.contacts.forEach((contact) => {
                            contact.value = contact.id;
                            contact.label = contact.firstName + ' ' + contact.lastName;
                        });
                    }
                    setOrder(response.data);
                    setIsLoaded(true);
                    scrollToBottom();
                } else {
                    toast.error('Lancement inconnu', toastOptions);
                    history.goBack();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [history, token, orderId, refreshData]);

    const handleSendMessage = () => {
        if(message !== '') {
            axios.post(process.env.REACT_APP_UPA_API_HOST + 'orders/' + orderId + '/messages', { message: {type: 'message',content: message}}, { headers: { Authorization: 'Bearer ' + token }})
                .then(() => {
                    setMessage('');
                    setRefreshData(refreshData + 1);
                })
                .catch(err => {
                    toast.error(err.response.data.message, toastOptions);
                })
        }
    }

    const scrollToBottom = async () => {
        if(messagesEndRef.current) {
            await smoothScrollIntoView(messagesEndRef.current, { scrollMode: 'if-needed', block: 'nearest', behavior: 'smooth', inline: 'nearest' })
        }
    }

    React.useEffect(async () => {
        await scrollToBottom();
    }, [order]);

    return isLoaded ? (
        <div className='flex-view'>
            <div className='left-container scrollable-page'>
                <Switch>
                    <Route exact path={path}>
                        <OrderView order={order} setOrder={setOrder} />
                    </Route>
                    <Route path={path + '/edit'}>
                        <OrderEdit order={order} setOrder={setOrder} setRefreshData={setRefreshData} refreshData={refreshData}/>
                    </Route>
                    <Route path={ path + '*'}>
                        <Redirect to={path} />
                    </Route>
                </Switch>

            </div>
            <div className='right-container'>
                <div className='scrollable-actions'>
                    { order.actions && order.actions.length > 0 && order.actions.map((action, index) => (
                        <CustomAction action={action} key={index} />
                    ))}
                    <div ref={messagesEndRef} className='custom-action' />
                </div>
                <div className='action-sender-container'>
                    <div className='action-sender-input'>
                        <textarea rows={2} value={message} onChange={(e) => setMessage(e.target.value)} placeholder='Écrivez un message au fournisseur...' />
                    </div>
                    <div className='action-sender-button'>
                        <button className='sender-button' onClick={handleSendMessage} />
                    </div>
                </div>
            </div>
        </div>
    ) : (<div className='loader-container'><Loader type="Oval" color="#2C95E8" height={30} width={30} /></div>)
}

export default OrderContainer;