import React, {useState} from "react";
import {CSVLink} from "react-csv";
import constants from "../../assets/constants/constants";

const CustomDownloader = ({data, columns, filename}) => {

    const [formattedColumns, setFormattedColumns] = useState(null);
    const [formattedData, setFormattedData] = useState(null);

    React.useEffect(() => {
        if(data && columns) {
            let filteredColumns =  columns.filter(column => column.isDownloaded === undefined || (column.isDownloaded && column.isDownloaded === true));
            setFormattedColumns(filteredColumns.map((column) => {
                return {...column, key: column.downloadAccessor ? column.downloadAccessor : column.accessor, label: column.Header }
            }));
            const newData = JSON.parse(JSON.stringify(data));
            setFormattedData(newData.map(row => {
                for (let dataKey in row) {
                    row[dataKey] = constants.all.find(constant => constant.value === row[dataKey]) ? constants.all.find(constant => constant.value === row[dataKey]).label : row[dataKey]
                }
                return row;
            }))
        }
    }, [data, columns]);

    return formattedData && formattedColumns ? (
        <CSVLink data={formattedData} filename={filename ? filename : "extract.csv"} headers={formattedColumns}>
            <button className='main-button download'>Télécharger</button>
        </CSVLink>
    ) : null;
}

export default CustomDownloader;