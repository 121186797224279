import React, {useContext, useState} from "react";
import Loader from "react-loader-spinner";
import fabrics from "../../../assets/icons/material.svg";
import production from "../../../assets/icons/production.svg";
import logistics from "../../../assets/icons/logistics.svg";
import CustomModal from "../../custom/CustomModal";
import AuthContext from "../../../contexts/AuthContext";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import axios from "axios";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/constants/toast";

const ProviderAdd = () => {

    const [provider, setProvider] = useState({ name: '', type: null, address: '', phones: [{ phone: '' }], contacts: []});
    const [newContact, setNewContact] = useState({ type: 'provider', firstName: '', lastName: '', email: '', phone: '' });
    const [editedContact, setEditedContact] = useState({ type: 'provider', firstName: '', lastName: '', email: '', phone: '' });
    const [editedContactIndex, setEditedContactIndex] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const { token } = useContext(AuthContext);
    const history = useHistory();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { register: registerContact, handleSubmit: handleSubmitContact, formState: { errors: contactErrors } } = useForm();

    React.useEffect(() => {
        console.log(provider);
    }, [provider]);

    const handleCreate = () => {
        setButtonLoading(true);
        axios.post(process.env.REACT_APP_UPA_API_HOST + 'providers' , { provider }, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                console.log(response);
                setButtonLoading(false);
                toast.success("Le fournisseur a bien été ajouté", toastOptions);
                history.goBack();
            })
            .catch((error) => {
                toast.error(error.response.data.message, toastOptions);
                setButtonLoading(false);
            });
    }

    const handleEditModalShow = (isShow) => {
        if(!isShow) {
            setEditedContact({ firstName: '', lastName: '', email: '', phone: '' });
            setEditModalShow(false);
        }
    }

    const handleShowUpdateContact = (index) => {
        setEditedContact(provider.contacts[index]);
        setEditedContactIndex(index);
        setEditModalShow(true);
    }

    const handleEditContact = () => {
        setProvider((provider) => {
            let tab = [...provider.contacts];
            tab[editedContactIndex] = editedContact;
            return {...provider, contacts: tab }
        });
        setEditModalShow(false);
    }

    const handleAddPhone = () => {
        setProvider((provider) => {
            let tab = provider.phones;
            tab = [...tab, { phone: ''}];
            return {...provider, phones: tab }
        })
    }

    const handleDeletePhone = (index) => {
        setProvider((provider) => {
            const tab = [...provider.phones];
            tab.splice(index, 1);
            return {...provider, phones: tab}
        })
    }

    const handleDeleteContact = (index) => {
        setProvider((provider) => {
            const tab = [...provider.contacts];
            tab.splice(index, 1);
            return {...provider, contacts: tab}
        })
    }

    const handleAddContact = () => {
        setProvider((provider) => {
            let tab = provider.contacts;
            tab = [...tab, newContact];
            return {...provider, contacts: tab }
        });
        setNewContact({ type: 'provider', firstName: '', lastName: '', email: '', phone: ''});
        setModalShow(false);
    }

    return (
        <div>
            <div className='list-header'>
                <div className='header-input'>
                    <input {...register("providerName", { required: true })} type='text' className={(errors.providerName ? 'name-input-required' : '')} placeholder='Nom du fournisseur' value={provider.name} onChange={(e) => { setProvider((provider) => { return { ...provider, name: e.target.value }}) }} />
                </div>
                <div className='header-buttons'>
                    <button onClick={history.goBack} className='main-button without-icon warning-button'>Annuler</button>
                    <button className='main-button blue-button plus margin-left' onClick={handleSubmit(handleCreate)} disabled={buttonLoading}>{ buttonLoading ? <Loader type="Oval" color="#FFFFFF" height={30} width={20} /> : 'Ajouter le fournisseur' }</button>
                </div>
            </div>
            <div className='input-group without-top'>
                <div className='input-row-container align-columns'>
                    <span className="input-label type">Type de fournisseur <span className='required'>*</span></span>
                    <div className='provider-container'>
                        <div className={'provider-block fabrics' + (provider.type === 'fabrics' ? ' checked' : '')} onClick={() => setProvider(() => { return {...provider, type: 'fabrics'}})}>
                            <div className='provider-check'/>
                            <div className='provider-content'>
                                <img src={fabrics} alt='material' />
                                <span>Tissus</span>
                            </div>
                        </div>
                        <div className={'provider-block production' + (provider.type === 'production' ? ' checked' : '')} onClick={() => setProvider(() => { return {...provider, type: 'production'}})}>
                            <div className='provider-check'/>
                            <div className='provider-content '>
                                <img src={production} alt='material' />
                                <span>Production</span>
                            </div>
                        </div>
                        <div className={'provider-block logistics' + (provider.type === 'logistics' ? ' checked' : '')} onClick={() => setProvider(() => { return {...provider, type: 'logistics'}})}>
                            <div className='provider-check'/>
                            <div className='provider-content'>
                                <img src={logistics} alt='material' />
                                <span>Logistique</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label location">Adresse <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={provider.address} className='classic-input' placeholder='Entrez une adresse' onChange={(e) => {
                            setProvider((provider) => { return { ...provider, address:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container align-columns'>
                    <span className="input-label phone">Téléphone <span className='required'>*</span></span>
                    { provider.phones.map((phone, index) => (
                        <div className='input-container margin-bottom-15' key={index}>
                            <input value={phone.phone} className={'search-input '} placeholder='Entrez un numéro de téléphone' onChange={(e) => {
                                setProvider((provider) => {
                                    const tab = provider.phones
                                    tab[index].phone = e.target.value
                                    return {...provider, phones: tab}
                                })
                            }} />
                            { index === 0 ? null : <button className='delete-icon-button' onClick={() => handleDeletePhone(index)} /> }
                        </div>
                    ))}
                    <button className='main-button small-button blue-button' onClick={handleAddPhone}>Ajouter un numéro</button>
                </div>
                <div className='input-row-container align-columns'>
                    <span className="input-label user">Contacts <span className='required'>*</span></span>
                    { provider.contacts.map((contact, index) => (
                        <div className='contact-container' key={index}>
                            <div className='contact-info'>
                                <span>{contact.firstName}</span>
                                <span>{contact.lastName}</span>
                                <span>{contact.email}</span>
                                <span>{contact.phone}</span>
                            </div>
                            <div className='contact-update'>
                                <button className='update-icon-button' onClick={() => handleShowUpdateContact(index)} />
                            </div>
                            <div className='contact-delete'>
                                <button className='delete-icon-button' onClick={() => handleDeleteContact(index)} />
                            </div>
                        </div>
                    ))}
                    <div className='small-button-container'>
                        <button className='main-button small-button blue-button' onClick={() => setModalShow(true)}>Ajouter un contact</button>
                    </div>
                </div>
            </div>
            <CustomModal title={'Nouveau contact'} setShow={setModalShow} show={modalShow}>
                <div className='input-row-container'>
                    <span className="input-label user">Prénom <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={newContact.firstName} className={'classic-input ' + (contactErrors.firstName ? 'input-required' : '')} placeholder='Entrez un prénom' onChange={(e) => {
                            setNewContact((contact) => { return { ...contact, firstName:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label user">Nom <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={newContact.lastName} className={'classic-input ' + (contactErrors.lastName ? 'input-required' : '')} placeholder='Entrez un nom' {...registerContact("lastName", { required: true })} onChange={(e) => {
                            setNewContact((contact) => { return { ...contact, lastName:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label email">Email <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={newContact.email} className={'classic-input ' + (contactErrors.email ? 'input-required' : '')} placeholder='Entrez un email' {...registerContact("email", { required: true })} onChange={(e) => {
                            setNewContact((contact) => { return { ...contact, email:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label phone">Téléphone <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={newContact.phone} className={'classic-input ' + (contactErrors.phone ? 'input-required' : '')} placeholder='Entrez un téléphone' {...registerContact("phone", { required: true })} onChange={(e) => {
                            setNewContact((contact) => { return { ...contact, phone:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='submit-right-container'>
                    <button className="main-button blue-button plus" onClick={handleSubmitContact(handleAddContact)}>Ajouter le contact</button>
                </div>
            </CustomModal>
            <CustomModal title={'Éditer le contact'} setShow={handleEditModalShow} show={editModalShow}>
                <div className='input-row-container'>
                    <span className="input-label user">Prénom <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={editedContact.firstName} className='classic-input' placeholder='Entrez un prénom' onChange={(e) => {
                            setEditedContact((contact) => { return { ...contact, firstName:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label user">Nom <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={editedContact.lastName} className='classic-input' placeholder='Entrez un nom' onChange={(e) => {
                            setEditedContact((contact) => { return { ...contact, lastName:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label email">Email <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={editedContact.email} className='classic-input' placeholder='Entrez un email' onChange={(e) => {
                            setEditedContact((contact) => { return { ...contact, email:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label phone">Téléphone <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={editedContact.phone} className='classic-input' placeholder='Entrez un téléphone' onChange={(e) => {
                            setEditedContact((contact) => { return { ...contact, phone:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='submit-right-container'>
                    <button className="main-button blue-button validate" onClick={handleEditContact}>Modifier le contact</button>
                </div>
            </CustomModal>
        </div>
    );
}

export default ProviderAdd;
