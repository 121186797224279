import React from "react";
import CustomTag from "./CustomTag";
import constants from "../../assets/constants/constants";

const CustomOption = ({ innerProps, isDisabled, label, ...props }) => {

    return (
        !isDisabled ? (
            !props.data.searchBar ? (
                <div {...innerProps} className='custom-tag-container'>
                    <CustomTag value={label} className={props.data.type} />
                </div>
            ) : (
                <div {...innerProps} className='custom-tag-container'>
                    <CustomTag value={ constants.all.find(constant => constant.value === props.data.dataType).value } className={props.data.dataType + ' margin-right'} />
                    <CustomTag value={label} className={props.data.type} />
                </div>
            )
        ) : null
    );
}

export default CustomOption;